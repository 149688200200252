import { store } from 'app/store'
import axios from 'axios'
import globalRouter from 'globalRouter'
import routes from 'routes'
import { API_BASE_URL } from 'utils/config'

const BASE_URL = API_BASE_URL

const customAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
  timeout: 60000,
})

customAxios.interceptors.request.use(function (config) {
  const token = store.getState().auth.userToken
  config.headers.Authorization = token ? `Bearer ${token}` : null
  return config
})

// interceptor for handling network errors
customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    if (error.response?.status == 401 && globalRouter.navigate) globalRouter.navigate(routes.login)
    return Promise.reject(error)
  }
)

const customAxiosFile = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  transformRequest: [
    (data) => {
      const formData = new FormData()
      Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) data[key].forEach((file) => formData.append(key, file))
        else formData.append(key, data[key])
      })
      return formData
    },
  ],
  withCredentials: false,
  timeout: 60000,
})

customAxiosFile.interceptors.request.use(function (config) {
  const token = store.getState().auth.userToken
  config.headers.Authorization = token ? `Bearer ${token}` : null
  return config
})

// interceptor for handling network errors
customAxiosFile.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    if (error.response?.status == 401 && globalRouter.navigate) globalRouter.navigate(routes.login)
    return Promise.reject(error)
  }
)

export default customAxios
export { customAxiosFile }
