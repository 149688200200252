export default {
  home: '/', // Tu pewnie redirect poleci na dashboard admin/user, ale poleci merge

  // Auth
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  confirmEmail: '/auth/confirm-email/:token',

  // User
  user: '/user', // Dashboard
  userProjects: '/user/projects',
  userMyTasks: '/user/my-tasks',
  userProjectsDetails: '/user/projects/:projectId',
  userBillings: '/user/billings',

  // Admin
  admin: '/admin', // Dashboard
  adminProjects: '/admin/projects',
  adminProjectsDetails: '/admin/projects/:projectId',
  adminProjectsEdit: '/admin/projects/:projectId/edit',
  adminProjectsCreate: '/admin/projects/create',

  adminProjectsDetailsTask: '/admin/projects/:projectId/tasks/:taskId',
  adminProjectsEditTask: '/admin/projects/:projectId/tasks/:taskId/edit',
  adminProjectsCreateTasks: '/admin/projects/:projectId/create-tasks',
  adminProjectsImportTasks: '/admin/projects/:projectId/import-tasks',

  // Others
  adminBillings: '/admin/billings',
  adminBillingsUserDetails: '/admin/billings/users/:userId/:month/:year',
  adminUsers: '/admin/users',
  adminUsersDetails: '/admin/users/:userId',
  adminSettings: '/admin/settings',

  // Common
  profile: '/profile', //

  // Others
  noMatch: '*',
  test: '/test',
}
