import { CustomYup } from 'validations/CustomYup'
import { emailFieldSchema, firstNameFieldSchema, lastNameFieldSchema, phoneFieldSchema } from '../helpers'

const personalDataSchema = CustomYup.object().shape({
  firstName: firstNameFieldSchema.required(),
  lastName: lastNameFieldSchema.required(),
  phoneNumber: phoneFieldSchema.required(),
  email: emailFieldSchema.required(),
})

export default personalDataSchema
