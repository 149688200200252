import customAxios from 'services/customAxios'

const urls = {
  profileGeneral: 'users/:userId/profile',
  profileContract: 'users/:userId/profile-contract',
}

const updateProfileGeneralData = (userId, data) =>
  customAxios({
    method: 'put',
    url: urls.profileGeneral.replace(':userId', userId),
    data,
  })

const updateProfileContractData = (userId, data) =>
  customAxios({
    method: 'put',
    url: urls.profileContract.replace(':userId', userId),
    data,
  })

export const profileService = {
  updateProfileGeneralData,
  updateProfileContractData,
}
