import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/joy'
import Breadcrumbs from 'components/Breadcrumbs'
import MainLayout from 'components/templates/MainLayout'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { adminService } from 'services/adminService'
import { handleErrorMessage, resolveAndDownloadBlob } from 'utils/helpers'

const AdminSettingsView = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const handleGlobalTaskExport = () => {
    setInnerLoading(true)
    adminService
      .downloadExportUserTasks()
      .then((res) => {
        resolveAndDownloadBlob(res, 'export_users_tasks_global.csv')
        enqueueSnackbar('Pobieranie pliku exportu.', { variant: 'success' })
      })
      .catch((err) => enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' }))
      .finally(() => setInnerLoading(false))
  }

  const handleGlobalUsersContractDataExport = () => {
    setInnerLoading(true)
    adminService
      .downloadExportUserContractData()
      .then((res) => {
        resolveAndDownloadBlob(res, 'export_users_contract_data.csv')
        enqueueSnackbar('Pobieranie pliku exportu.', { variant: 'success' })
      })
      .catch((err) => enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' }))
      .finally(() => setInnerLoading(false))
  }

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Ustawienia aplikacji' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Ustawienia aplikacji
        </Typography>
      </Box>
      <Stack spacing={2} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
        <Card orientation="horizontal" variant="outlined" sx={{ flexBasis: '500px' }}>
          <CardContent>
            <Typography level="body-md">Exportuj zadania użytkowników</Typography>
            <Typography level="body-sm">Ta czynność może chwilę potrwać, nie wyłączaj okna przeglądarki.</Typography>
            <Box sx={{ mt: 2 }}>
              <Button onClick={handleGlobalTaskExport} size="sm" variant="solid" loading={innerLoading}>
                Exportuj zadania
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Card orientation="horizontal" variant="outlined" sx={{ flexBasis: '500px' }}>
          <CardContent>
            <Typography level="body-md">Exportuj dane do umowy użytkowników</Typography>
            <Typography level="body-sm">Ta czynność może chwilę potrwać, nie wyłączaj okna przeglądarki.</Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleGlobalUsersContractDataExport}
                size="sm"
                variant="solid"
                color="success"
                loading={innerLoading}
              >
                Exportuj dane do umowy
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </MainLayout>
  )
}

export default AdminSettingsView
