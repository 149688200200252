import dayjs from 'dayjs'

export const handleErrorMessage = (error, defaultErrorMessage) => {
  if (error.response && error.response.data.message && Array.isArray(error.response.data.message))
    return error.response.data.message.join(' & ')
  if (error.response && error.response.data.message) return error.response.data.message
  return defaultErrorMessage ? defaultErrorMessage : error.message
}

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const isBoolean = (n) => !!n === n
export const isNumber = (n) => +n === n
export const isString = (n) => '' + n === n

export const displayHumanBytes = (bytes, dm = 2) => {
  if (bytes == 0) return '0 Bytes'
  const k = 1024,
    sizes = [`${bytes <= 1 ? 'Byte' : 'Bytes'}`, 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const resolveAndDownloadBlob = (response, filename) => {
  filename = decodeURI(filename)
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(url)
  link.remove()
}

export const standarizeStatus = (status) => status.toLowerCase()

export const formatMoney = (value, skipSymbol = false) => {
  if (typeof value === 'string') value = parseFloat(value)
  // eslint-disable-next-line no-undef
  return Intl.NumberFormat(
    'pl-Pl',
    skipSymbol
      ? {}
      : {
          style: 'currency',
          currency: 'PLN',
          currencyDisplay: 'narrowSymbol',
        }
  ).format(value)
}

export const isDateBefore = (compare, now = dayjs()) => dayjs(compare).isBefore(now, 'days')
export const isDateBeforeOrToday = (compare, now = dayjs()) =>
  dayjs(compare).isBefore(now, 'days') || dayjs(compare).isSame(now, 'days')
export const isDateToday = (compare, now = dayjs()) => dayjs(compare).isSame(now, 'days')
export const isDateAfterOrToday = (compare, now = dayjs()) =>
  dayjs(compare).isAfter(now, 'days') || dayjs(compare).isSame(now, 'days')
export const isDateAfter = (compare, now = dayjs()) => dayjs(compare).isAfter(now, 'days')

export const sortByDate = (arr) => [...arr].sort((a, b) => new Date(a.date) - new Date(b.date))
export const sortBy = (arr, propToSort) => [...arr].sort((a, b) => b[propToSort] - a[propToSort])

export const isTaskStatusOpen = (task, serverTime) => !task.assignment && isDateAfter(task.date, serverTime)

export const addComputedFullName = (user) => {
  if (user.fullName) return user
  else if (user.firstName && user.lastName) return { ...user, computedFullName: `${user.firstName} ${user.lastName}` }
  return user
}
