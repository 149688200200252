import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    required: 'To pole jest wymagane.',
  },
  string: {
    email: 'Niepoprawny adres e-mail.',
  },
})

export const CustomYup = Yup
