import { Divider, Stack, Tab, tabClasses, TabList, TabPanel, Tabs } from '@mui/joy'
import PropTypes from 'prop-types'

const CustomPageTabs = ({ tabs, initialTabSlug = tabs[0].slug }) => {
  return (
    <Tabs defaultValue={initialTabSlug} sx={{ bgcolor: 'transparent' }}>
      <TabList
        size="sm"
        sx={{
          boxShadow: { xs: 'none', sm: 'inset 0 -1px var(--joy-palette-divider);' },
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 1,
          justifyContent: 'left',
          [`&& .${tabClasses.root}`]: {
            fontWeight: '600',
            flex: 'initial',
            color: 'text.tertiary',
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.slug}
            disableIndicator
            sx={{
              borderRadius: { xs: '6px', sm: '6px 6px 0 0' },
              justifyContent: 'flex-start',
              p: { xs: '6px 24px', sm: '3px 12px' },
            }}
            value={tab.slug}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <Divider sx={{ my: 2, display: { sm: 'none' } }} />
      <Stack
        sx={{
          display: 'flex',
          maxWidth: '700px',
          width: '100%',
          mx: 'auto',
          py: { xs: 0, sm: 2, md: 3 },
        }}
      >
        {tabs.map((tab) => (
          <TabPanel key={tab.slug} value={tab.slug} sx={{ p: 0, pb: 2 }}>
            {tab.component}
          </TabPanel>
        ))}
      </Stack>
    </Tabs>
  )
}

CustomPageTabs.propTypes = {
  initialTabSlug: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      slug: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    })
  ).isRequired,
}
export default CustomPageTabs
