import customAxios from 'services/customAxios'

const urls = {
  login: 'auth/login',
  getMe: 'auth/me',
  register: 'auth/register',
  logout: 'auth/logout',
  confirmEmail: 'auth/confirm-email',
  resetPassword: 'auth/reset-password',
}

const login = (data) =>
  customAxios({
    method: 'post',
    url: urls.login,
    data,
  })

const getMe = () =>
  customAxios({
    method: 'get',
    url: urls.getMe,
  })

const logout = () =>
  customAxios({
    method: 'get',
    url: urls.logout,
  })

const register = (data) =>
  customAxios({
    method: 'post',
    url: urls.register,
    data,
  })

const confirmEmail = (token) =>
  customAxios({
    method: 'get',
    url: urls.confirmEmail,
    params: {
      token,
    },
  })

const resetPassword = (data) =>
  customAxios({
    method: 'post',
    url: urls.resetPassword,
    data,
  })

export const authService = {
  login,
  getMe,
  logout,
  register,
  confirmEmail,
  resetPassword,
}
