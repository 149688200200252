import PropTypes from 'prop-types'
import { InfoOutlined } from '@mui/icons-material'
import { Autocomplete, FormControl, FormHelperText, FormLabel } from '@mui/joy'

const CustomAutocompleteControl = ({ name, label, formik, options, sx, labelProp = 'label', ...rest }) => {
  return (
    <FormControl error={formik.touched[name] && Boolean(formik.errors[name])} sx={sx}>
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        options={options}
        // Object are not equal

        getOptionLabel={(opt) => opt[labelProp]}
        getOptionKey={(opt) => opt.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={formik.values[name]}
        onChange={(e, value) => formik.setFieldValue(name, value)}
        onBlur={formik.handleBlur}
        onFocus={formik.handleBlur}
        slotProps={{
          listbox: {
            disablePortal: true,
          },
          input: {
            autoComplete: 'new-password', // disable autocomplete and autofill
            id: name,
            name,
          },
        }}
        {...rest}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText sx={{ gap: 1 }}>
          <InfoOutlined />
          {formik.errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default CustomAutocompleteControl

CustomAutocompleteControl.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  labelProp: PropTypes.string,
}
