import Sheet from '@mui/joy/Sheet'
import IconButton from '@mui/joy/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

import { toggleSidebar } from 'utils/muiHelpers'

const Header = () => {
  return (
    <Sheet
      sx={{
        display: { xs: 'flex', md: 'none' },
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 'var(--Header-height)',
        zIndex: 9995,
        p: 2,
        gap: 1,
        borderBottom: '1px solid',
        borderColor: 'background.level1',
        boxShadow: 'sm',
      }}
    >
      <IconButton onClick={() => toggleSidebar()} variant="outlined" color="neutral" size="sm">
        <MenuIcon />
      </IconButton>
    </Sheet>
  )
}

export default Header
