import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Alert, Button, Divider, Link, Typography, Stack } from '@mui/joy'
import { cleanupRequestStatus, registerUser } from 'features/authSlice'
import { Warning } from '@mui/icons-material'
import routes from 'routes'
import registerSchema from 'validations/auth/registerSchema'
import CustomInputControl from 'components/forms/CustomInputControl'
import CustomCheckboxControl from 'components/forms/CustomCheckboxControl'
import AuthLayout from 'components/templates/AuthLayout'
import PageLoader from 'components/PageLoader'

const RegisterView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error, success, userInfo } = useSelector((s) => s.auth)

  useEffect(() => {
    return () => dispatch(cleanupRequestStatus())
  }, [])

  useEffect(() => {
    if (success)
      navigate(routes.login, {
        state: {
          message: 'Twoje konto na platformie zostało utworzone. <br /><strong>Potwierdź swój adres e-mail</strong>',
        },
      })
    if (userInfo) navigate(routes.home)
  }, [navigate, userInfo, success])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      acceptTerms: false,
    },
    validationSchema: registerSchema,
    onSubmit: (values) => dispatch(registerUser(values)),
  })

  if (userInfo) return <PageLoader />

  return (
    <AuthLayout>
      <Stack sx={{ gap: 4, mb: 2 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography component="h1" level="h3">
            Rejestracja
          </Typography>
          <Typography level="body-sm">
            Posiadasz już konto?{' '}
            <Link level="title-sm" component={RouterLink} to={routes.login}>
              Zaloguj się!
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={(theme) => ({
          [theme.getColorSchemeSelector('light')]: {
            color: { xs: '#FFF', md: 'text.tertiary' },
          },
        })}
      >
        lub
      </Divider>
      <Stack sx={{ gap: 4, mt: 2 }}>
        {error && (
          <Alert color="danger" variant="soft" startDecorator={<Warning />}>
            {error}
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <CustomInputControl name="firstName" label="Imię" formik={formik} />
          <CustomInputControl name="lastName" label="Nazwisko" formik={formik} />
          <CustomInputControl name="phoneNumber" label="Numer telefonu" formik={formik} />
          <CustomInputControl name="email" label="E-mail" formik={formik} />
          <CustomInputControl name="password" label="Hasło" formik={formik} type="password" />
          <CustomInputControl name="confirmPassword" label="Powtórz hasło" formik={formik} type="password" />
          <CustomCheckboxControl
            name="acceptTerms"
            label={
              <>
                Oświadczam, że zapoznałem/-am się z{' '}
                <Link
                  target="_blank"
                  href="https://pomiarywtransporcie.pl/polityka_prywatnosci-pomiary-w-transporcie.pdf"
                  sx={{ zIndex: 1 }}
                >
                  Polityką prywatności
                </Link>
                .
              </>
            }
            formik={formik}
          />
          <Stack sx={{ gap: 4, mt: 2 }}>
            <Button type="submit" fullWidth disabled={loading} loading={loading}>
              Zarejestruj
            </Button>
          </Stack>
        </form>
      </Stack>
    </AuthLayout>
  )
}

export default RegisterView
