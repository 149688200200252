import PropTypes from 'prop-types'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, FormHelperText, FormLabel, Radio, RadioGroup, Typography } from '@mui/joy'

const getBooleanValue = (value) => {
  if (value === 'true') return true
  if (value === 'false') return false
  return value
}
const CustomRadioControl = ({ name, label, options, formik, sx, ...rest }) => {
  const isBooleanField = options.every((opt) => typeof opt.value === 'boolean')

  return (
    <FormControl error={formik.touched[name] && Boolean(formik.errors[name])} sx={sx}>
      <FormLabel>
        <Typography level="body-sm">{label}</Typography>
      </FormLabel>

      <RadioGroup
        name={name}
        value={formik.values[name]}
        onChange={(e) => {
          if (!isBooleanField) return formik.handleChange(e)
          const boolValue = getBooleanValue(e.target.value)
          if ([true, false].includes(boolValue)) formik.setFieldValue(name, boolValue)
          else formik.handleChange(e)
        }}
        onBlur={formik.handleBlur}
        sx={{ my: 1 }}
        {...rest}
      >
        {options.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            label={<Typography level="body-sm">{option.label}</Typography>}
          />
        ))}
      </RadioGroup>

      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText sx={{ gap: 1, ml: 0, mt: 1.5 }}>
          <InfoOutlined />
          {formik.errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default CustomRadioControl

CustomRadioControl.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  formik: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    })
  ).isRequired,
}
