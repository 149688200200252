import { useEffect, useMemo, useState } from 'react'
import { Alert, Box, Typography } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Warning } from '@mui/icons-material'
import { cleanupRequestStatus, getProjects } from 'features/projectsSlice'
import usePagination from 'hooks/usePagination'
import Breadcrumbs from 'components/Breadcrumbs'
import BaseTable from 'components/tables/BaseTable'
import FilterSearch from 'components/tables/FilterSearch'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import routes from 'routes'

const headers = [
  {
    label: 'Nazwa projektu',
    propName: 'name',
    type: 'route',
    route: routes.userProjectsDetails,
    routeReplace: ':projectId',
  },
  { label: 'Lokalizacja', propName: 'location', rowClassName: 'text-center', className: 'text-center' },
  {
    label: 'Stawka brutto',
    propName: 'hourlyRate',
    type: 'moneyRate',
    rowClassName: 'text-center',
    className: 'text-center',
  },
]

const UserProjectsView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')

  const { projects, loading, error } = useSelector((s) => s.projects)

  const pagination = usePagination()

  const handleGoToProjectPage = (projectId) => navigate(routes.userProjectsDetails.replace(':projectId', projectId))

  const params = useMemo(
    () => ({
      search: search || null,
    }),
    [search]
  )

  useEffect(() => {
    dispatch(getProjects({ params }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, params])

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Projekty' }]} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projekty
        </Typography>
      </Box>

      <FiltersWrapper
        renderSearch={<FilterSearch label="Wyszukiwanie projektu" value={search} setValue={setSearch} />}
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}

      <BaseTable
        headers={headers}
        data={projects}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        actions={[{ label: 'Zobacz projekt', func: (el) => handleGoToProjectPage(el.id) }]}
        noDataText="Niedługo pojawi się tutaj pierwszy projekt."
      />

      {/* <TablePaginationDesktop pagination={pagination} /> */}
    </MainLayout>
  )
}

export default UserProjectsView
