import { CustomYup } from 'validations/CustomYup'
import { phoneFieldSchema } from 'validations/helpers'

const newProjectSchema = CustomYup.object().shape({
  name: CustomYup.string().required(),
  description: CustomYup.string().required(),
  startDate: CustomYup.string().required(),
  endDate: CustomYup.string().required(),
  location: CustomYup.string().required(),
  hourlyRate: CustomYup.number().min(0, 'Stawka nie może być mniejsza niż 0.').required(),
  coordinationPhone: phoneFieldSchema.required(),
})

export default newProjectSchema
