import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Link, Breadcrumbs as JoyBreadcrumbs, Typography } from '@mui/joy'
import { ChevronRightRounded, HomeRounded } from '@mui/icons-material'
import routes from 'routes'

const renderItem = (item) => {
  return item.to ? (
    <Link key={item.label} component={RouterLink} underline="none" to={item.to} aria-label={item.label}>
      <Typography color="primary" level="body-xs">
        {item.label}
      </Typography>
    </Link>
  ) : (
    <Typography key={item.label} color="neutral" level="body-xs">
      {item.label}
    </Typography>
  )
}
const Breadcrumbs = ({ items }) => {
  return (
    <JoyBreadcrumbs
      size="sm"
      aria-label="breadcrumbs"
      separator={<ChevronRightRounded fontSize="small" />}
      sx={{ pl: 0 }}
    >
      <Link component={RouterLink} underline="none" color="neutral" to={routes.home} aria-label="Home">
        <HomeRounded />
      </Link>
      {items.map((el) => renderItem(el))}
    </JoyBreadcrumbs>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumbs
