import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'

import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { AddTask, Upload, Warning } from '@mui/icons-material'
import { Alert, Box, Button, Card, CardContent, Stack, Typography } from '@mui/joy'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import { formatMoney, handleErrorMessage, isDateAfterOrToday, standarizeStatus } from 'utils/helpers'
import { projectService } from 'services/projectsService'
import { DATE_FORMAT, dateFilters, dateFiltersOptions, STATUS } from 'utils/config'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import BaseTable from 'components/tables/BaseTable'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import DeleteModal from 'components/DeleteModal'
import FilterSelect from 'components/tables/FilterSelect'

const getHeaders = (project) => [
  { label: 'Nr', propName: 'lp', className: 'width-sm' },
  { label: 'Data', propName: 'date', type: 'date', className: 'width-sm' },
  { label: 'Godziny', propName: 'hours' },
  { label: 'Ilość godzin', propName: 'hoursCount', rowClassName: 'text-center', className: 'text-center width-sm' },
  {
    label: 'Wynagrodzenie brutto',
    type: 'computed',
    propName: 'not_used',
    rowClassName: 'text-right',
    className: 'text-right width-xl',
    fn: (data) => {
      if (!project || !project.hourlyRate || !data.hoursCount) return '-'
      return formatMoney(project.hourlyRate * Number(data.hoursCount))
    },
  },
  { label: 'Lokalizacja', propName: 'localization', rowClassName: 'text-center', className: 'text-center' },
  {
    label: 'Pracownik',
    propName: 'assignment',
    type: 'redirectAdminUser',
    rowClassName: 'text-center',
    className: 'text-center',
  },
  {
    label: 'Status',
    propName: 'assignment2',
    type: 'assigmentModeAdmin',
    rowClassName: 'text-center',
    className: 'text-center',
  },
]

const AdminProjectsDetailsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const [deleteTaskObject, setDeleteTaskObject] = useState(false)
  const [dateFilter, setDateFilter] = useState(dateFilters.current.value)

  const { projectId } = useParams()
  const { currentProject, loading, error } = useSelector((s) => s.projects)
  const { serverTime } = useSelector((s) => s.auth)

  const pagination = usePagination()

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const filteredTasks = useMemo(() => {
    if (!currentProject || !currentProject.tasks) return []
    if (dateFilter === dateFilters.current.value)
      return currentProject.tasks.filter((t) => isDateAfterOrToday(t.date, serverTime))
    else return currentProject.tasks
  }, [currentProject, serverTime, dateFilter])

  const handleGoToTaskDetailsPage = (taskId) =>
    navigate(routes.adminProjectsDetailsTask.replace(':projectId', projectId).replace(':taskId', taskId), {
      state: { from: currentProject.name },
    })
  const handleGoToProjectTaskEditPage = (taskId) =>
    navigate(routes.adminProjectsEditTask.replace(':projectId', projectId).replace(':taskId', taskId), {
      state: { from: currentProject.name },
    })

  const handleApproveTask = (task) => {
    setInnerLoading(true)
    projectService
      .adminApproveProjectTask(projectId, task.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zadanie zostało zaakceptowane.', { variant: 'success' })
        setDeleteTaskObject(false)
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleDeleteTask = () => {
    setInnerLoading(true)
    projectService
      .deleteProjectTask(projectId, deleteTaskObject.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zadanie zostało usunięte.', { variant: 'success' })
        setDeleteTaskObject(false)
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (!projectId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <DeleteModal
        open={deleteTaskObject}
        handleDelete={handleDeleteTask}
        loading={innerLoading}
        setObject={setDeleteTaskObject}
      >
        Czy jesteś pewny, ze chcesz usunąć zadanie?
      </DeleteModal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[{ label: 'Projekty', to: routes.adminProjects }, { label: currentProject?.name || '?' }]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projekt:&nbsp;
          <Typography color="primary">{currentProject?.name}</Typography>
        </Typography>
        <Stack rowGap={1} columnGap={2} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
          <Button
            size="sm"
            component={Link}
            to={routes.adminProjectsCreateTasks.replace(':projectId', projectId)}
            color="primary"
            state={{ from: currentProject?.name }}
            startDecorator={<AddTask fontSize="small" />}
          >
            Dodaj zadania
          </Button>
          <Button
            size="sm"
            component={Link}
            to={routes.adminProjectsImportTasks.replace(':projectId', projectId)}
            color="primary"
            state={{ from: currentProject?.name }}
            variant="outlined"
            startDecorator={<Upload fontSize="small" />}
          >
            Importuj zadania
          </Button>
        </Stack>
      </Box>

      {currentProject && (
        <Card orientation="horizontal" variant="outlined">
          <CardContent>
            <Stack rowGap={0.5} columnGap={3} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Lokalizacja:
                </Typography>{' '}
                {currentProject.location}
              </Typography>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Data rozpoczęcia:
                </Typography>{' '}
                {dayjs(currentProject.startDate).format(DATE_FORMAT)}
              </Typography>
              <Typography>
                {' '}
                <Typography color="primary" fontWeight={500}>
                  Data zakończenia:
                </Typography>{' '}
                {dayjs(currentProject.endDate).format(DATE_FORMAT)}
              </Typography>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Stawka godzinowa brutto:
                </Typography>{' '}
                {currentProject.hourlyRate} zł/h
              </Typography>
            </Stack>
            <Typography level="body-sm">{currentProject.description}</Typography>
          </CardContent>
        </Card>
      )}

      <FiltersWrapper
        renderFilters={
          <FilterSelect
            label="Filtrowanie po dacie"
            placeholder={dateFilters.all.label}
            options={dateFiltersOptions}
            value={dateFilter}
            setValue={setDateFilter}
            sx={{ ml: { sm: 'auto' } }}
          />
        }
      />

      <BaseTable
        headers={currentProject ? getHeaders(currentProject) : []}
        data={filteredTasks}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        actions={[
          {
            label: 'Podgląd zadania',
            color: 'primary',
            func: (el) => handleGoToTaskDetailsPage(el.id),
          },
          {
            label: 'Zaakceptuj',
            color: 'success',
            divider: true,
            func: handleApproveTask,
            checkIfDisable: (el) => standarizeStatus(el.status) === STATUS.done.value,
          },
          {
            label: 'Edytuj zadanie',
            color: 'warning',
            divider: true,
            func: (el) => handleGoToProjectTaskEditPage(el.id),
            checkIfDisable: (el) => standarizeStatus(el.status) === STATUS.done.value,
          },
          { label: 'Usuń zadanie', color: 'danger', divider: true, func: (el) => setDeleteTaskObject(el) },
        ]}
        noDataText="Brak zadań do wyświetlenia."
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}

      {/* <TablePaginationDesktop pagination={pagination} /> */}
    </MainLayout>
  )
}

export default AdminProjectsDetailsView
