import { useEffect } from 'react'
import { Link as RouterLink, Navigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { cleanupRequestStatus, getUserBillingsDetails } from 'features/billingsSlice'
import { Alert, Box, Card, CardContent, Link, Stack, Typography } from '@mui/joy'
import { Warning } from '@mui/icons-material'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import usePagination from 'hooks/usePagination'
import BaseTable from 'components/tables/BaseTable'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import { formatMoney, standarizeStatus } from 'utils/helpers'
import StatusChip from 'components/UI/StatusChip'
import { STATUS } from 'utils/config'

const headers = [
  {
    label: 'Nr',
    type: 'computed',
    propName: 'not_used',
    className: 'width-sm',
    fn: (task) => (
      <Link
        component={RouterLink}
        to={routes.adminProjectsDetailsTask.replace(':projectId', task.project?.id).replace(':taskId', task.id)}
        state={{ from: task.project?.name }}
        level="title-sm"
      >
        <Typography color="primary" level="body-xs">
          {task.lp || 'brak'}
        </Typography>
      </Link>
    ),
  },
  { label: 'Data', propName: 'date', type: 'date', className: 'width-sm' },
  {
    label: 'Projekt',
    type: 'computed',
    propName: 'not_used_2',
    fn: (task) => (
      <Link
        component={RouterLink}
        to={routes.adminProjectsDetails.replace(':projectId', task.project?.id)}
        level="title-sm"
      >
        <Typography color="primary" level="body-xs">
          {task.project?.name}
        </Typography>
      </Link>
    ),
  },
  { label: 'Ilość godzin', propName: 'hoursCount', rowClassName: 'text-center', className: 'text-center width-sm' },
  {
    label: 'Wynagrodzenie brutto',
    type: 'computed',
    propName: 'not_used_3',
    rowClassName: 'text-right',
    className: 'text-right width-xl',
    fn: (task) => formatMoney(task.hoursCount * task.projectHourlyRate),
  },
  {
    label: 'Status',
    type: 'computed',
    propName: 'not_used_4',
    rowClassName: 'text-center',
    className: 'text-center width-xl',
    fn: (task) => (
      <Typography color="primary" level="body-xs">
        <StatusChip
          status={
            standarizeStatus(task.status) === STATUS.done.value ? STATUS.approved.value : STATUS.notapproved.value
          }
        />
      </Typography>
    ),
  },
]

const AdminBillingsUserDetails = () => {
  const { userId, month, year } = useParams()
  const pagination = usePagination()
  const dispatch = useDispatch()

  const { usersBillingsDetails, loading, error } = useSelector((s) => s.billings)

  useEffect(() => {
    dispatch(getUserBillingsDetails({ userId, params: { month, year } }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  if (!userId || !month || !year) return <Navigate to={routes.adminBillings} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Rozliczenia', to: routes.adminBillings },
            { label: `${usersBillingsDetails?.firstName} ${usersBillingsDetails?.lastName} / ${month} / ${year}` },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Rozliczenia:&nbsp;
          <Typography color="primary">{`${usersBillingsDetails?.firstName} ${usersBillingsDetails?.lastName} [${month}/${year}]`}</Typography>
        </Typography>
      </Box>

      {usersBillingsDetails && (
        <Card orientation="horizontal" variant="outlined">
          <CardContent>
            <Stack rowGap={0.5} columnGap={3} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Wynagrodzenie niezaakceptowane:
                </Typography>{' '}
                {formatMoney(usersBillingsDetails.pendingAmount)}
              </Typography>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Wynagrodzenie zaakceptowane:
                </Typography>{' '}
                {formatMoney(usersBillingsDetails.acceptedAmount)}
              </Typography>{' '}
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Wynagrodzenie suma:
                </Typography>{' '}
                {formatMoney(usersBillingsDetails.totalAmount)}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      )}

      <FiltersWrapper />

      <BaseTable
        headers={headers}
        data={usersBillingsDetails?.tasks || []}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        noDataText="Brak rozliczeń do wyświetlenia."
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}
    </MainLayout>
  )
}

export default AdminBillingsUserDetails
