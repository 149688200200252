import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Box, Chip, LinearProgress, Link, Typography } from '@mui/joy'
import { Link as RouterLink } from 'react-router-dom'
import { CheckCircle, HighlightOff } from '@mui/icons-material'
import { DATE_FORMAT, STATUS } from 'utils/config'
import {
  formatMoney,
  isBoolean,
  isDateAfter,
  isDateAfterOrToday,
  isDateBefore,
  isDateBeforeOrToday,
  isDateToday,
  standarizeStatus,
} from 'utils/helpers'
import StatusChip from 'components/UI/StatusChip'
import AdvancedTooltip from 'components/UI/AdvancedTooltip'
import routes from 'routes'

const getUserStatus = (task, userInfo, serverTime) => {
  if (!task.assignment && isDateAfter(task.date, serverTime)) return { label: 'Otwarte', color: 'primary' }
  if (!task.assignment && isDateBeforeOrToday(task.date, serverTime))
    return { label: 'Przeterminowane', color: 'neutral' }
  if (task.assignment && task.assignment.userId === userInfo.id && standarizeStatus(task.status) === STATUS.done.value)
    return { label: 'Zaakceptowane', color: 'success' }
  if (task.assignment && task.assignment.userId === userInfo.id && isDateAfterOrToday(task.date, serverTime))
    return { label: 'Twoje zadanie', color: 'success' }
  if (task.assignment && task.assignment.userId === userInfo.id && isDateBefore(task.date, serverTime))
    return { label: 'Weryfikacja', color: 'warning' }
  if (task.reserveList && task.reserveList.some((id) => id === userInfo.id) && isDateAfter(task.date, serverTime))
    return { label: 'Lista rezerwowa', color: 'primary' }
  if (task.assignment && task.assignment.userId !== userInfo.id && isDateBeforeOrToday(task.date, serverTime))
    return { label: 'Zamknięte', color: 'neutral' }
  if (task.assignment && task.assignment.userId !== userInfo.id) return { label: 'Zajęte', color: 'danger' }
  return { label: '?', color: 'danger' }
}

const getAdminStatus = (task, serverTime) => {
  if (task.assignment && standarizeStatus(task.status) === STATUS.done.value)
    return { label: 'Zaakceptowane', color: 'success' }
  if (task.assignment && isDateAfter(task.date, serverTime)) return { label: 'Podjęte', color: 'primary' }
  if (task.assignment && isDateToday(task.date, serverTime)) return { label: 'W trakcie', color: 'warning' }
  if (task.assignment && isDateBefore(task.date, serverTime) && standarizeStatus(task.status) !== STATUS.done.value)
    return { label: 'Weryfikacja', color: 'warning' }
  if (!task.assignment && isDateAfter(task.date, serverTime)) return { label: 'Otwarte', color: 'primary' }
  if (!task.assignment && isDateBeforeOrToday(task.date, serverTime))
    return { label: 'Przeterminowane', color: 'neutral' }
  return { label: '?', color: 'danger' }
}

const TableRowContent = ({ row, header, isListContent = false }) => {
  const { userInfo, serverTime } = useSelector((s) => s.auth)

  let content = null

  switch (header.type) {
    case 'notes':
      if (isListContent) content = <Typography level="body-xs">{row.notes}</Typography>
      else content = row.notes ? <AdvancedTooltip title="Uwagi do zadania:" description={row.notes} /> : ''
      break
    case 'computed':
      content = <Typography level="body-xs">{header.fn ? header.fn(row) : ''}</Typography>
      break
    case 'route':
      content = (
        <Link
          component={RouterLink}
          to={header.route.replace(
            header.routeReplace,
            header.routeReplaceWith ? row[header.routeReplaceWith] : row.id
          )}
          level="title-sm"
        >
          <Typography color="primary" level="body-xs">
            {row[header.propName]}
          </Typography>
        </Link>
      )
      break
    case 'date':
      content = dayjs(row[header.propName]).format(DATE_FORMAT)
      break
    case 'status':
      content = <StatusChip status={row[header.propName]} />
      break
    case 'redirectAdminUser':
      if (row[header.propName]?.userId)
        content = (
          <Link
            component={RouterLink}
            to={routes.adminUsersDetails.replace(':userId', row[header.propName].userId)}
            level="title-sm"
          >
            <Typography color="primary" level="body-xs">
              {row[header.propName].firstName} {row[header.propName].lastName}
              {row.reserveList && (
                <Typography color="warning" level="body-xs">
                  &nbsp;+{row.reserveList.length}
                </Typography>
              )}
            </Typography>{' '}
          </Link>
        )
      else content = <Typography level="body-xs">–</Typography>
      break
    case 'assigmentModeUser':
      {
        const userStatus = getUserStatus(row, userInfo, serverTime)
        content = (
          <Chip size="sm" color={userStatus.color} variant="plain">
            {userStatus.label}
          </Chip>
        )
      }
      break
    case 'assigmentModeAdmin':
      {
        const adminStatus = getAdminStatus(row, serverTime)
        content = (
          <Chip size="sm" color={adminStatus.color} variant="plain">
            {adminStatus.label}
          </Chip>
        )
      }
      break
    case 'check':
      content = row[header.propName] ? (
        <CheckCircle fontSize="small" color="success" />
      ) : (
        <HighlightOff fontSize="small" color="danger" />
      )
      break
    case 'moneyRate':
      content = <Typography level="body-xs">{row[header.propName]} zł/h</Typography>
      break
    case 'money':
      content = <Typography level="body-xs">{formatMoney(row[header.propName])}</Typography>
      break
    case 'progress':
      content = (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LinearProgress determinate variant="solid" value={Number(row[header.propName])} />
          <Typography level="body-xs">{Math.round(Number(row[header.propName]))}%</Typography>
        </Box>
      )
      break
    default:
      content = (
        <Typography level="body-xs">
          {isBoolean(row[header.propName] || row[header.propName] === null) ? '' : row[header.propName] || '–'}
        </Typography>
      )
  }

  return content
}

export default TableRowContent
