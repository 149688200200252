import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Box, Button, Divider, Stack, Typography, Card, CardActions, CardOverflow } from '@mui/joy'
import CustomInputControl from 'components/forms/CustomInputControl'
import changePasswordSchema from 'validations/profile/changePasswordSchema'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { handleErrorMessage } from 'utils/helpers'
import { getMe } from 'features/authSlice'
import { profileService } from 'services/profileService'

const ChangePasswordSubView = ({ userInfo }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const { id } = userInfo

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values, { resetForm }) => {
      setInnerLoading(true)
      profileService
        .updateProfileGeneralData(id, {
          oldPassword: values.oldPassword,
          password: values.newPassword,
        })
        .then((res) => {
          console.log(res)
          enqueueSnackbar('Hasło zostało zaktualizowane', { variant: 'success' })
          resetForm()
          dispatch(getMe())
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
        })
        .finally(() => setInnerLoading(false))
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Zmień hasło</Typography>
          <Typography level="body-sm">Edytuj swoje hasło</Typography>
        </Box>
        <Divider />
        <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 2 }}>
          <CustomInputControl name="oldPassword" label="Stare hasło" formik={formik} type="password" />
          <Divider />
          <CustomInputControl name="newPassword" label="Nowe hasło" formik={formik} type="password" />
          <CustomInputControl name="confirmNewPassword" label="Powtórz nowe hasło" formik={formik} type="password" />
        </Stack>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button
              onClick={() => formik.resetForm()}
              size="sm"
              variant="outlined"
              color="neutral"
              disabled={innerLoading}
            >
              Anuluj
            </Button>
            <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={innerLoading}>
              Zapisz zmiany
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </form>
  )
}

ChangePasswordSubView.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}
export default ChangePasswordSubView
