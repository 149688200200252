import { useRef } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormLabel, IconButton, Option, Select } from '@mui/joy'
import { CloseRounded } from '@mui/icons-material'

const FilterSelect = ({ label, placeholder, options, value, setValue, allowDelete = true, sx }) => {
  const action = useRef(null)
  return (
    <FormControl size="sm" sx={sx}>
      <FormLabel>{label}</FormLabel>
      <Select
        action={action}
        value={value || null}
        onChange={(_, newValue) => setValue(newValue)}
        size="sm"
        placeholder={placeholder}
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        sx={{ minWidth: 170 }}
        {...(value &&
          allowDelete && {
            endDecorator: (
              <IconButton
                variant="plain"
                color="neutral"
                onMouseDown={(e) => e.stopPropagation()}
                onClick={() => {
                  setValue('')
                  action.current?.focusVisible()
                }}
              >
                <CloseRounded />
              </IconButton>
            ),
            indicator: null,
          })}
      >
        {options.map((opt) => (
          <Option key={opt.value} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
    </FormControl>
  )
}

FilterSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool,
  sx: PropTypes.object,
}

export default FilterSelect
