import { Info, Payments, Phone, Place } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardOverflow, Divider, Stack, Typography } from '@mui/joy'
import CustomDatePickerControl from 'components/forms/CustomDatePickerControl'
import CustomEditorControl from 'components/forms/CustomEditorControl'
import CustomInputControl from 'components/forms/CustomInputControl'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import newProjectSchema from 'validations/project/newProjectSchema'

const ProjectFormSubView = ({ handleSubmit, initialValues, loading = false }) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: newProjectSchema,
    onSubmit: handleSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          display: 'flex',
          maxWidth: '800px',
          width: '100%',
          mx: 'auto',
          px: { xs: 2, md: 3 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md" startDecorator={<Info />} color="primary">
            Uzupełnij dane do projektu
          </Typography>
        </Box>
        <Divider />
        <Stack direction="column" spacing={2} sx={{ mt: 1, mb: 2 }}>
          <CustomInputControl name="name" label="Tytuł projektu" formik={formik} sx={{ gridColumn: '1/-1' }} />
          <CustomEditorControl name="description" label="Opis projektu" formik={formik} sx={{ gridColumn: '1/-1' }} />
          <Stack flexWrap="wrap" useFlexGap direction={{ lg: 'row' }} spacing={{ xs: 2 }}>
            <CustomDatePickerControl
              name="startDate"
              label="Data startowa"
              formik={formik}
              sx={{ flex: '1', width: '100%' }}
            />
            <CustomDatePickerControl
              name="endDate"
              label="Data końcowa"
              formik={formik}
              sx={{ flex: '1', width: '100%' }}
            />
            <CustomInputControl
              name="location"
              label="Lokalizacja"
              formik={formik}
              startDecorator={<Place />}
              sx={{ flex: '1', width: '100%' }}
            />
            <CustomInputControl
              type="number"
              name="hourlyRate"
              label="Stawka za godzinę pomiaru brutto"
              formik={formik}
              startDecorator={<Payments />}
              endDecorator="PLN"
              sx={{ flex: '1', width: '100%' }}
            />
            <CustomInputControl
              type="text"
              name="coordinationPhone"
              label="Telefon koordynacyjny"
              formik={formik}
              startDecorator={<Phone />}
              sx={{ flex: '1', width: '100%' }}
            />
          </Stack>
        </Stack>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button onClick={() => formik.resetForm()} size="sm" variant="outlined" color="neutral" disabled={loading}>
              Anuluj
            </Button>
            <Button type="submit" disabled={!formik.dirty} size="sm" variant="solid" loading={loading}>
              Zapisz zmiany
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </form>
  )
}

ProjectFormSubView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    hourlyRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.number.isRequired,
    coordinationPhone: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
}

export default ProjectFormSubView
