import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Warning } from '@mui/icons-material'
import { Alert, Box, Typography } from '@mui/joy'
import { projectService } from 'services/projectsService'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import { handleErrorMessage } from 'utils/helpers'
import routes from 'routes'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import ProjectFormSubView from './common/ProjectFormSubView'
import PageLoader from 'components/PageLoader'

const AdminProjectsEditView = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const { projectId } = useParams()
  const { currentProject, loading, error } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const handleEditProjectSubmit = (values) => {
    // Extract type, bc its not allowed by api onUpdate
    const { type: _type, ...updateValues } = values
    setInnerLoading(true)
    projectService
      .updateProject(projectId, updateValues)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Projekt został zaktualizaowany', { variant: 'success' })
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const {
    name = '',
    description = '',
    startDate = '',
    endDate = '',
    location = '',
    hourlyRate = '',
    type = 1,
    coordinationPhone = '',
  } = currentProject || {}

  if (!projectId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            {
              label: currentProject?.name || projectId,
              to: routes.adminProjectsDetails.replace(':projectId', projectId),
            },
            { label: 'Edycja' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Edytuj projekt
        </Typography>
      </Box>

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}

      {currentProject && (
        <ProjectFormSubView
          handleSubmit={handleEditProjectSubmit}
          initialValues={{
            name,
            description,
            startDate,
            endDate,
            location,
            hourlyRate,
            type,
            coordinationPhone,
          }}
          loading={innerLoading}
        />
      )}
    </MainLayout>
  )
}

export default AdminProjectsEditView
