import { AspectRatio, Card, CardContent, Chip, Typography } from '@mui/joy'
import { InsertDriveFileRounded } from '@mui/icons-material'
import { displayHumanBytes } from 'utils/helpers'

// eslint-disable-next-line react/prop-types
export default function FileUpload({ icon, handleDelete, fileName, fileSize, sx, ...other }) {
  return (
    <Card
      size="sm"
      variant="outlined"
      orientation="horizontal"
      {...other}
      sx={[
        {
          gap: 1.5,
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <AspectRatio
        ratio="1"
        variant="soft"
        color="neutral"
        sx={{ minWidth: 32, borderRadius: '50%', '--Icon-fontSize': '16px' }}
      >
        <div>{icon ?? <InsertDriveFileRounded fontSize="small" />}</div>
      </AspectRatio>
      <CardContent>
        <Typography color="success" level="title-sm">
          {fileName} -&nbsp;
          <Typography color="warning" level="body-xs">
            {displayHumanBytes(fileSize)}
          </Typography>
        </Typography>
      </CardContent>
      <Chip size="sm" variant="outlined" color="danger" onClick={handleDelete}>
        Usuń plik
      </Chip>
    </Card>
  )
}
