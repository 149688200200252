import PropTypes from 'prop-types'
import { InfoOutlined } from '@mui/icons-material'
import { Checkbox, FormControl, FormHelperText, Typography } from '@mui/joy'

const CustomCheckboxControl = ({ name, label, formik, sx, smallText = false, ...rest }) => (
  <FormControl error={formik.touched[name] && Boolean(formik.errors[name])} sx={sx}>
    <Checkbox
      label={<Typography level={smallText ? 'body-xs' : 'body-sm'}>{label}</Typography>}
      variant="outlined"
      id={name}
      name={name}
      checked={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      {...rest}
    />
    {formik.touched[name] && formik.errors[name] && (
      <FormHelperText sx={{ gap: 1, ml: 0, mt: 1.5 }}>
        <InfoOutlined />
        {formik.errors[name]}
      </FormHelperText>
    )}
  </FormControl>
)

export default CustomCheckboxControl

CustomCheckboxControl.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  formik: PropTypes.object.isRequired,
  smallText: PropTypes.bool,
}
