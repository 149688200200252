import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { FileUploadRounded } from '@mui/icons-material'
import { AspectRatio, Card, FormLabel, Typography } from '@mui/joy'

const CustomUploadFileControl = ({ name, label, formik, sx, accept }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    multiple: false,
    onDrop: (acceptedFiles) => {
      formik.setFieldValue(name, acceptedFiles)
    },
  })
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <div>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Card
            variant="soft"
            sx={[
              {
                borderRadius: 'sm',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                px: 3,
                flexGrow: 1,
                boxShadow: 'none',
                cursor: 'pointer',
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            <AspectRatio
              ratio="1"
              variant="solid"
              color="primary"
              sx={{ minWidth: 32, borderRadius: '50%', '--Icon-fontSize': '16px' }}
            >
              <div>
                <FileUploadRounded />
              </div>
            </AspectRatio>
            <Typography level="body-sm" sx={{ textAlign: 'center' }}>
              {isDragActive ? (
                <span>Upuść pliki tutaj ...</span>
              ) : (
                <>
                  <Typography color="primary">Kliknij</Typography>, aby przesłać lub przeciągnij i upuść plik .csv
                </>
              )}
            </Typography>
          </Card>
        </div>
      </div>
    </>
  )
}

CustomUploadFileControl.propTypes = {
  sx: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  accept: PropTypes.object.isRequired,
}

export default CustomUploadFileControl
