import PropTypes from 'prop-types'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy'

const CustomInputControl = ({ name, label, formik, sx, ...rest }) => {
  let fieldName = name
  let error = formik.errors[name]
  let touched = formik.touched[name]
  let value = formik.values[name]

  if (Array.isArray(name)) {
    const [mainProp, index, fieldProp] = name
    fieldName = name.join('.')
    error = formik.errors[mainProp] && formik.errors[mainProp][index] && formik.errors[mainProp][index][fieldProp]
    touched = formik.touched[mainProp] && formik.touched[mainProp][index] && formik.touched[mainProp][index][fieldProp]
    value = formik.values[mainProp][index][fieldProp]
  }

  return (
    <FormControl error={touched && Boolean(error)} sx={sx} disabled={rest.disabled}>
      <FormLabel>{label}</FormLabel>
      <Input
        id={fieldName}
        name={fieldName}
        label={label}
        value={value}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched && Boolean(error)}
        {...rest}
      />
      {touched && error && (
        <FormHelperText sx={{ gap: 1 }}>
          <InfoOutlined />
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default CustomInputControl

CustomInputControl.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
}
