import { Box, Tooltip, Typography, Link } from '@mui/joy/'
import { Info } from '@mui/icons-material'
import PropTypes from 'prop-types'

const AdvancedTooltip = ({ title, description }) => {
  return (
    <Tooltip
      variant="outlined"
      arrow
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 320,
            justifyContent: 'center',
            p: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1, width: '100%', mt: 0 }}>
            <Info color="primary" fontSize="small" />
            <div>
              <Typography sx={{ fontWeight: 'lg', fontSize: 'sm' }}>{title}</Typography>
              <Typography textColor="text.secondary" sx={{ fontSize: 'sm' }}>
                {description}
              </Typography>
            </div>
          </Box>
        </Box>
      }
    >
      <Link
        href="#info"
        underline="none"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        endDecorator={<Info color="primary" fontSize="small" />}
      />
    </Tooltip>
  )
}

AdvancedTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default AdvancedTooltip
