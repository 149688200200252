import customAxios from 'services/customAxios'
import { ROLES } from 'utils/config'

const urls = {
  adminAllUsers: 'admin/users/autocomplete',
}

const getUsers = () =>
  customAxios({
    method: 'get',
    url: urls.adminAllUsers,
    params: {
      role: ROLES.user.value,
    },
  })

export const autocompleteService = {
  getUsers,
}
