import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { usersService } from 'services/usersService'
import { sortByDate } from 'utils/helpers'

const initialState = {
  tasks: [],
  loading: false,
  error: null,
  success: false,
}

export const getMyTasks = createAsyncThunk('myTasks', async (userId, { rejectWithValue }) => {
  try {
    const response = await usersService.getUserTasks(userId)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clean: () => {
      return initialState
    },
    cleanupRequestStatus: (state) => {
      state.error = false
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      // Get myTasks list
      .addCase(getMyTasks.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getMyTasks.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.tasks = sortByDate(payload)
      })
      .addCase(getMyTasks.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export const { clean, cleanupRequestStatus } = tasksSlice.actions

export default tasksSlice.reducer
