import { CustomYup } from 'validations/CustomYup'
import { passwordFieldSchema } from '../helpers'

const changePasswordSchema = CustomYup.object().shape({
  oldPassword: passwordFieldSchema.required(),
  newPassword: passwordFieldSchema.required(),
  confirmNewPassword: CustomYup.string()
    .oneOf([CustomYup.ref('newPassword')], 'Hasła muszą być identyczne.')
    .required(),
})

export default changePasswordSchema
