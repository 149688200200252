import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Alert, Box, Typography } from '@mui/joy'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { Warning } from '@mui/icons-material'
import { projectService } from 'services/projectsService'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import { handleErrorMessage } from 'utils/helpers'
import routes from 'routes'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import TasksFormSubView from './common/TasksFormSubView'
import PageLoader from 'components/PageLoader'

const AdminTaskEditView = () => {
  const dispatch = useDispatch()

  const { projectId, taskId } = useParams()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const { currentProject, loading, error } = useSelector((s) => s.projects)

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const pickedTask = currentProject ? currentProject.tasks.find((task) => task.id === taskId) : null
  const { date = '', hours = '', hoursCount = '', localization = '', notes = '', lp = '' } = pickedTask || {}

  const handleUpdateTask = (values) => {
    setInnerLoading(true)
    const { tasks } = values

    if (!tasks[0]) return enqueueSnackbar('Obiekt zadania jest nieprawidłowy.', { variant: 'error' })

    projectService
      .adminUpdateProjectTask(projectId, taskId, values.tasks[0])
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zadanie zostało zaktualizowane', { variant: 'success' })
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (!projectId || !taskId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            { label: state?.from || projectId, to: routes.adminProjectsDetails.replace(':projectId', projectId) },
            { label: 'Edycja zadania' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Edytuj zadanie
        </Typography>
      </Box>

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}

      {pickedTask && (
        <TasksFormSubView
          handleSubmit={handleUpdateTask}
          loading={innerLoading}
          initialTasks={[
            {
              date,
              hours,
              hoursCount: Number(hoursCount),
              localization,
              notes,
              lp,
            },
          ]}
          singleMode
        />
      )}
    </MainLayout>
  )
}

export default AdminTaskEditView
