import customAxios from 'services/customAxios'

const urls = {
  adminUsers: 'admin/users',
  adminUsersDetails: 'admin/users/:userId',
  adminUsersActivate: 'admin/users/:userId/confirm',
  adminUsersDelete: 'admin/users/:userId',

  userBillings: 'billings',

  adminUsersBillings: 'admin/users/billings',
  adminUserBillingsDetails: 'admin/users/:userId/billings',

  adminProfileGeneral: 'admin/users/:userId/profile',
  adminProfileContract: 'admin/users/:userId/profile-contract',

  userGetMyTasks: 'users/:userId/tasks',
}

const getUsers = ({ params = null }) =>
  customAxios({
    method: 'get',
    url: urls.adminUsers,
    params,
  })

const getUserDetails = (userId) =>
  customAxios({
    method: 'get',
    url: urls.adminUsersDetails.replace(':userId', userId),
  })

const getUserTasks = (userId) =>
  customAxios({
    method: 'get',
    url: urls.userGetMyTasks.replace(':userId', userId),
  })

const adminUsersActivate = (userId) =>
  customAxios({
    method: 'get',
    url: urls.adminUsersActivate.replace(':userId', userId),
  })

const updateUserGeneralData = (userId, data) =>
  customAxios({
    method: 'put',
    url: urls.adminProfileGeneral.replace(':userId', userId),
    data,
  })

const updateUserContractData = (userId, data) =>
  customAxios({
    method: 'put',
    url: urls.adminProfileContract.replace(':userId', userId),
    data,
  })

const deleteUser = (userId) =>
  customAxios({
    method: 'delete',
    url: urls.adminUsersDelete.replace(':userId', userId),
  })

const adminUsersBillings = ({ params = null }) =>
  customAxios({
    method: 'get',
    url: urls.adminUsersBillings,
    params,
  })

const adminUserBillingsDetails = ({ userId, params = null }) =>
  customAxios({
    method: 'get',
    url: urls.adminUserBillingsDetails.replace(':userId', userId),
    params,
  })

const usersBillings = () =>
  customAxios({
    method: 'get',
    url: urls.userBillings,
  })

export const usersService = {
  getUsers,
  getUserDetails,
  getUserTasks,
  adminUsersActivate,
  updateUserGeneralData,
  updateUserContractData,
  deleteUser,
  adminUsersBillings,
  adminUserBillingsDetails,
  usersBillings,
}
