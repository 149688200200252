/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import 'dayjs/locale/pl'
import Input from '@mui/joy/Input'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useClearableField } from '@mui/x-date-pickers/hooks'
import { unstable_useDateField as useDateField } from '@mui/x-date-pickers/DateField'
import { FormHelperText } from '@mui/joy'
import { InfoOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'

const JoyField = forwardRef((props, ref) => {
  const {
    label,
    formikError,
    formikTouched,
    sx,
    disabled,
    id,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    // formControlSx,
    endDecorator,
    startDecorator,
    slotProps,
    inputRef,
    ...other
  } = props

  return (
    <FormControl error={formikTouched && Boolean(formikError)} disabled={disabled} id={id} ref={ref} sx={sx}>
      <FormLabel>{label}</FormLabel>
      <Input
        ref={ref}
        disabled={disabled}
        startDecorator={
          <>
            {startAdornment}
            {startDecorator}
          </>
        }
        endDecorator={
          <>
            {endAdornment}
            {endDecorator}
          </>
        }
        slotProps={{
          ...slotProps,
          root: { ...slotProps?.root, ref: containerRef },
          input: {
            ...slotProps?.input,
            ref: inputRef,
          },
        }}
        {...other}
        error={formikTouched && Boolean(formikError)}
      />
      {formikTouched && formikError && (
        <FormHelperText sx={{ gap: 1 }}>
          <InfoOutlined />
          {formikError}
        </FormHelperText>
      )}
    </FormControl>
  )
})

const JoyDateField = forwardRef((props, ref) => {
  const { slots, slotProps, ...textFieldProps } = props

  // eslint-disable-next-line no-unused-vars
  const { enableAccessibleFieldDOMStructure, ...fieldResponse } = useDateField({
    ...textFieldProps,
    enableAccessibleFieldDOMStructure: null,
  })

  /* If you don't need a clear button, you can skip the use of this hook */
  const processedFieldProps = useClearableField({
    ...fieldResponse,
    slots,
    slotProps,
  })

  return <JoyField ref={ref} {...processedFieldProps} />
})

const JoyDatePicker = forwardRef((props, ref) => {
  return (
    <DatePicker
      ref={ref}
      {...props}
      slots={{ ...props.slots, field: JoyDateField }}
      slotProps={{
        ...props.slotProps,
        field: {
          ...props.slotProps?.field,
        },
      }}
    />
  )
})

const CustomDatePickerControl = (props) => {
  const { formik, ...rest } = props
  const { name } = props
  let fieldName = name
  let error = formik.errors[name]
  let touched = formik.touched[name]
  let value = formik.values[name]

  if (Array.isArray(name)) {
    const [mainProp, index, fieldProp] = name
    fieldName = name.join('.')
    error = formik.errors[mainProp] && formik.errors[mainProp][index] && formik.errors[mainProp][index][fieldProp]
    touched = formik.touched[mainProp] && formik.touched[mainProp][index] && formik.touched[mainProp][index][fieldProp]
    value = formik.values[mainProp][index][fieldProp]
  }
  // console.log('1', props)
  // console.log('1value', formik.values)

  return (
    <LocalizationProvider adapterLocale="pl" dateAdapter={AdapterDayjs}>
      <JoyDatePicker
        slotProps={{
          field: {
            clearable: true,
            formikError: error,
            formikTouched: touched,
            name: fieldName,
            value: value ? dayjs(value) : null,
            onChange: (value) => formik.setFieldValue(fieldName, value),
          },
        }}
        onChange={(value) => formik.setFieldValue(fieldName, value)}
        {...rest}
        id={fieldName}
        name={fieldName}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePickerControl
