import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { projectService } from 'services/projectsService'
import { sortByDate } from 'utils/helpers'

const initialState = {
  currentTask: null,
  currentProject: null,
  currentTaskReserveUsers: [],
  projects: [],
  loading: false,
  error: null,
}

export const getProjects = createAsyncThunk('projects', async (data = {}, { rejectWithValue }) => {
  try {
    const response = await projectService.getProjects(data)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const getProjectDetails = createAsyncThunk('projects/details', async (projectId, { rejectWithValue }) => {
  try {
    const response = await projectService.getProjectDetails(projectId)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const getTaskDetails = createAsyncThunk('projects/task/details', async (data, { rejectWithValue }) => {
  const { projectId, taskId } = data
  try {
    const response = await projectService.getTaskDetails(projectId, taskId)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const getTaskReserveUsers = createAsyncThunk(
  'projects/task/reserve-users',
  async (data, { rejectWithValue }) => {
    const { projectId, taskId } = data
    try {
      const response = await projectService.getTaskReserveUsers(projectId, taskId)
      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
      return rejectWithValue(error.message)
    }
  }
)

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clean: () => {
      return initialState
    },
    cleanupRequestStatus: (state) => {
      state.error = false
      state.loading = false
    },
    updateCurrentProjectProp: (state, { payload }) => {
      state.currentProject[payload.prop] = payload.value
    },
    setCurrentProject: (state, { payload }) => {
      state.currentProject = payload
    },
    setCurrentTask: (state, { payload }) => {
      state.currentTask = payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Get projects list
      .addCase(getProjects.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.loading = false
        state.projects = payload
      })
      .addCase(getProjects.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      // Get project details
      .addCase(getProjectDetails.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getProjectDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.currentProject = { ...payload, tasks: sortByDate(payload.tasks) }
      })
      .addCase(getProjectDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      // Get task details
      .addCase(getTaskDetails.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getTaskDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.currentTask = payload
      })
      .addCase(getTaskDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      // Get task reserve users
      .addCase(getTaskReserveUsers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getTaskReserveUsers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.currentTaskReserveUsers = payload
      })
      .addCase(getTaskReserveUsers.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export const { clean, cleanupRequestStatus, updateCurrentProjectProp, setCurrentProject } = projectsSlice.actions

export default projectsSlice.reducer
