import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import Typography from '@mui/joy/Typography'

import MainLayout from 'components/templates/MainLayout'
import { DownloadRounded } from '@mui/icons-material'

const breadcrumbs = [{ label: 'Projekty' }]

const DashboardView = () => {
  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={breadcrumbs} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Orders
        </Typography>
        <Button color="primary" startDecorator={<DownloadRounded />} size="sm">
          Download PDF
        </Button>
      </Box>
      {/* <OrderTable />
      <OrderList /> */}
    </MainLayout>
  )
}

export default DashboardView
