import PropTypes from 'prop-types'
import { Box, Button, Divider, IconButton, Modal, ModalClose, ModalDialog, Sheet, Typography } from '@mui/joy'
import { FilterAlt } from '@mui/icons-material'
import { useState } from 'react'

const FiltersWrapper = ({ renderSearch, renderFilters, sx }) => {
  const [open, setOpen] = useState(false)

  return (
    <Box
      sx={{
        py: 2,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          alignItems: 'flex-end',
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
          ...sx,
        }}
      >
        {renderSearch}
        {renderFilters}
      </Box>
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'flex-end', gap: 1 }}>
        {renderSearch}
        {renderFilters && (
          <IconButton
            size="sm"
            variant="outlined"
            color="neutral"
            onClick={() => setOpen(true)}
            sx={{ alignSelf: 'flex-end' }}
          >
            <FilterAlt />
          </IconButton>
        )}
        <Modal open={open} onClose={() => setOpen(false)} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography level="h2">Filtrowanie</Typography>
            <Divider sx={{ my: 1 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters}
              <Button color="primary" onClick={() => setOpen(false)} sx={{ mt: 3 }}>
                Filtruj
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Box>
    </Box>
  )
}

FiltersWrapper.propTypes = {
  sx: PropTypes.object,
  renderSearch: PropTypes.node,
  renderFilters: PropTypes.node,
}

export default FiltersWrapper
