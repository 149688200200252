import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { usersService } from 'services/usersService'

const initialState = {
  currentUser: null,
  users: [],
  loading: false,
  error: null,
}

export const getUsers = createAsyncThunk('users', async (data = {}, { rejectWithValue }) => {
  try {
    const response = await usersService.getUsers(data)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const getUserDetails = createAsyncThunk('users/details', async (userId, { rejectWithValue }) => {
  try {
    const response = await usersService.getUserDetails(userId)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clean: () => {
      return initialState
    },
    cleanupRequestStatus: (state) => {
      state.error = false
      state.loading = false
    },
    updateCurrentUserProp: (state, { payload }) => {
      state.currentUser[payload.prop] = payload.value
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.users = payload
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.currentUser = payload
      })
      .addCase(getUserDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export const { clean, cleanupRequestStatus, updateCurrentUserProp, setCurrentUser } = usersSlice.actions

export default usersSlice.reducer
