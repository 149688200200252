import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cleanupRequestStatus, getMyTasks } from 'features/tasksSlice'
import { useLocation } from 'react-router-dom'
import routes from 'routes'

const LogicGetUserTasks = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { success } = useSelector((s) => s.tasks)
  const { userInfo } = useSelector((s) => s.auth)

  useEffect(() => {
    if (!userInfo || location.pathname === routes.userMyTasks || success) return
    dispatch(getMyTasks(userInfo.id))
    return () => dispatch(cleanupRequestStatus())
  }, [])

  return null
}

export default LogicGetUserTasks
