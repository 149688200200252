export const nationalitiesArray = [
  { id: 'AF', label: 'Afganistan' },
  { id: 'AL', label: 'Albania' },
  { id: 'DZ', label: 'Algieria' },
  { id: 'AD', label: 'Andora' },
  { id: 'AO', label: 'Angola' },
  { id: 'AG', label: 'Antigua i Barbuda' },
  { id: 'SA', label: 'Arabia Saudyjska' },
  { id: 'AR', label: 'Argentyna' },
  { id: 'AM', label: 'Armenia' },
  { id: 'AW', label: 'Aruba' },
  { id: 'AU', label: 'Australia' },
  { id: 'AT', label: 'Austria' },
  { id: 'AZ', label: 'Azerbejdżan' },
  { id: 'BS', label: 'Bahamy' },
  { id: 'BH', label: 'Bahrajn' },
  { id: 'BD', label: 'Bangladesz' },
  { id: 'BB', label: 'Barbados' },
  { id: 'BE', label: 'Belgia' },
  { id: 'BZ', label: 'Belize' },
  { id: 'BJ', label: 'Benin' },
  { id: 'BT', label: 'Bhutan' },
  { id: 'BY', label: 'Białoruś' },
  { id: 'BO', label: 'Boliwia' },
  { id: 'BA', label: 'Bośnia i Hercegowina' },
  { id: 'BW', label: 'Botswana' },
  { id: 'BR', label: 'Brazylia' },
  { id: 'BN', label: 'Brunei' },
  { id: 'BG', label: 'Bułgaria' },
  { id: 'BF', label: 'Burkina Faso' },
  { id: 'BI', label: 'Burundi' },
  { id: 'CL', label: 'Chile' },
  { id: 'CN', label: 'Chiny' },
  { id: 'HR', label: 'Chorwacja' },
  { id: 'CW', label: 'Curaçao' },
  { id: 'CY', label: 'Cypr' },
  { id: 'TD', label: 'Czad' },
  { id: 'ME', label: 'Czarnogóra' },
  { id: 'CZ', label: 'Czechy' },
  { id: 'DK', label: 'Dania' },
  { id: 'CD', label: 'Demokratyczna Republika Konga' },
  { id: 'DM', label: 'Dominika' },
  { id: 'DO', label: 'Dominikana' },
  { id: 'DJ', label: 'Dżibuti' },
  { id: 'EG', label: 'Egipt' },
  { id: 'EC', label: 'Ekwador' },
  { id: 'ER', label: 'Erytrea' },
  { id: 'EE', label: 'Estonia' },
  { id: 'SZ', label: 'Eswatini (Suasi)' },
  { id: 'ET', label: 'Etiopia' },
  { id: 'FJ', label: 'Fidżi' },
  { id: 'PH', label: 'Filipiny' },
  { id: 'FI', label: 'Finlandia' },
  { id: 'FR', label: 'Francja' },
  { id: 'GA', label: 'Gabon' },
  { id: 'GM', label: 'Gambia' },
  { id: 'GH', label: 'Ghana' },
  { id: 'GR', label: 'Grecja' },
  { id: 'GD', label: 'Grenada' },
  { id: 'GE', label: 'Gruzja' },
  { id: 'GY', label: 'Gujana' },
  { id: 'GT', label: 'Gwatemala' },
  { id: 'GN', label: 'Gwinea' },
  { id: 'GW', label: 'Gwinea Bissau' },
  { id: 'GQ', label: 'Gwinea Równikowa' },
  { id: 'HT', label: 'Haiti' },
  { id: 'ES', label: 'Hiszpania' },
  { id: 'NL', label: 'Holandia' },
  { id: 'HN', label: 'Honduras' },
  { id: 'IN', label: 'Indie' },
  { id: 'ID', label: 'Indonezja' },
  { id: 'IQ', label: 'Irak' },
  { id: 'IR', label: 'Iran' },
  { id: 'IE', label: 'Irlandia' },
  { id: 'IS', label: 'Islandia' },
  { id: 'IL', label: 'Izrael' },
  { id: 'JM', label: 'Jamajka' },
  { id: 'JP', label: 'Japonia' },
  { id: 'YE', label: 'Jemen' },
  { id: 'JE', label: 'Jersey' },
  { id: 'JO', label: 'Jordania' },
  { id: 'KH', label: 'Kambodża' },
  { id: 'CM', label: 'Kamerun' },
  { id: 'CA', label: 'Kanada' },
  { id: 'QA', label: 'Katar' },
  { id: 'KZ', label: 'Kazachstan' },
  { id: 'KE', label: 'Kenia' },
  { id: 'KG', label: 'Kirgistan' },
  { id: 'KI', label: 'Kiribati' },
  { id: 'CO', label: 'Kolumbia' },
  { id: 'KM', label: 'Komory' },
  { id: 'CG', label: 'Kongo' },
  { id: 'KR', label: 'Korea Południowa' },
  { id: 'KP', label: 'Korea Północna' },
  { id: 'XK', label: 'Kosowo' },
  { id: 'CR', label: 'Kostaryka' },
  { id: 'CU', label: 'Kuba' },
  { id: 'KW', label: 'Kuwejt' },
  { id: 'LA', label: 'Laos' },
  { id: 'LS', label: 'Lesotho' },
  { id: 'LB', label: 'Liban' },
  { id: 'LR', label: 'Liberia' },
  { id: 'LY', label: 'Libia' },
  { id: 'LI', label: 'Liechtenstein' },
  { id: 'LT', label: 'Litwa' },
  { id: 'LU', label: 'Luksemburg' },
  { id: 'LV', label: 'Łotwa' },
  { id: 'MK', label: 'Macedonia Północna' },
  { id: 'MG', label: 'Madagaskar' },
  { id: 'MW', label: 'Malawi' },
  { id: 'MV', label: 'Malediwy' },
  { id: 'MY', label: 'Malezja' },
  { id: 'ML', label: 'Mali' },
  { id: 'MT', label: 'Malta' },
  { id: 'MA', label: 'Maroko' },
  { id: 'MR', label: 'Mauretania' },
  { id: 'MU', label: 'Mauritius' },
  { id: 'MX', label: 'Meksyk' },
  { id: 'FM', label: 'Mikronezja' },
  { id: 'MM', label: 'Mjanma (Birma)' },
  { id: 'MD', label: 'Mołdawia' },
  { id: 'MC', label: 'Monako' },
  { id: 'MN', label: 'Mongolia' },
  { id: 'MZ', label: 'Mozambik' },
  { id: 'NA', label: 'Namibia' },
  { id: 'NR', label: 'Nauru' },
  { id: 'NP', label: 'Nepal' },
  { id: 'DE', label: 'Niemcy' },
  { id: 'NE', label: 'Niger' },
  { id: 'NG', label: 'Nigeria' },
  { id: 'NI', label: 'Nikaragua' },
  { id: 'NO', label: 'Norwegia' },
  { id: 'NZ', label: 'Nowa Zelandia' },
  { id: 'OM', label: 'Oman' },
  { id: 'PK', label: 'Pakistan' },
  { id: 'PW', label: 'Palau' },
  { id: 'PS', label: 'Palestyna' },
  { id: 'PA', label: 'Panama' },
  { id: 'PG', label: 'Papua-Nowa Gwinea' },
  { id: 'PY', label: 'Paragwaj' },
  { id: 'PE', label: 'Peru' },
  //   { id: 'PL', label: 'Polska' },
  { id: 'PL', label: 'Polskie' },
  { id: 'PT', label: 'Portugalia' },
  { id: 'ZA', label: 'Republika Południowej Afryki' },
  { id: 'CF', label: 'Republika Środkowoafrykańska' },
  { id: 'CV', label: 'Republika Zielonego Przylądka' },
  { id: 'RU', label: 'Rosja' },
  { id: 'RO', label: 'Rumunia' },
  { id: 'RW', label: 'Rwanda' },
  { id: 'EH', label: 'Sahara Zachodnia' },
  { id: 'KN', label: 'Saint Kitts i Nevis' },
  { id: 'LC', label: 'Saint Lucia' },
  { id: 'VC', label: 'Saint Vincent i Grenadyny' },
  { id: 'SV', label: 'Salwador' },
  { id: 'WS', label: 'Samoa' },
  { id: 'SM', label: 'San Marino' },
  { id: 'SN', label: 'Senegal' },
  { id: 'RS', label: 'Serbia' },
  { id: 'SC', label: 'Seszele' },
  { id: 'SL', label: 'Sierra Leone' },
  { id: 'SG', label: 'Singapur' },
  { id: 'SX', label: 'Sint Maarten' },
  { id: 'SK', label: 'Słowacja' },
  { id: 'SI', label: 'Słowenia' },
  { id: 'SO', label: 'Somalia' },
  { id: 'LK', label: 'Sri Lanka' },
  { id: 'US', label: 'Stany Zjednoczone' },
  { id: 'SD', label: 'Sudan' },
  { id: 'SS', label: 'Sudan Południowy' },
  { id: 'SR', label: 'Surinam' },
  { id: 'SY', label: 'Syria' },
  { id: 'CH', label: 'Szwajcaria' },
  { id: 'SE', label: 'Szwecja' },
  { id: 'TJ', label: 'Tadżykistan' },
  { id: 'TH', label: 'Tajlandia' },
  { id: 'TW', label: 'Tajwan' },
  { id: 'TZ', label: 'Tanzania' },
  { id: 'TL', label: 'Timor Wschodni' },
  { id: 'TG', label: 'Togo' },
  { id: 'TO', label: 'Tonga' },
  { id: 'TT', label: 'Trynidad i Tobago' },
  { id: 'TN', label: 'Tunezja' },
  { id: 'TR', label: 'Turcja' },
  { id: 'TM', label: 'Turkmenistan' },
  { id: 'TV', label: 'Tuvalu' },
  { id: 'UG', label: 'Uganda' },
  { id: 'UA', label: 'Ukraina' },
  { id: 'UY', label: 'Urugwaj' },
  { id: 'UZ', label: 'Uzbekistan' },
  { id: 'VU', label: 'Vanuatu' },
  { id: 'VA', label: 'Watykan' },
  { id: 'VE', label: 'Wenezuela' },
  { id: 'HU', label: 'Węgry' },
  { id: 'GB', label: 'Wielka Brytania' },
  { id: 'VN', label: 'Wietnam' },
  { id: 'IT', label: 'Włochy' },
  { id: 'CI', label: 'Wybrzeże Kości Słoniowej' },
  { id: 'IM', label: 'Wyspa Man' },
  { id: 'MH', label: 'Wyspy Marshalla' },
  { id: 'SB', label: 'Wyspy Salomona' },
  { id: 'ST', label: 'Wyspy Świętego Tomasza i Książęca' },
  { id: 'ZM', label: 'Zambia' },
  { id: 'ZW', label: 'Zimbabwe' },
  { id: 'AE', label: 'Zjednoczone Emiraty Arabskie' },
]

export const voivodshipsArray = [
  { id: 'dolnoslaskie', label: 'Dolnośląskie' },
  { id: 'kujawsko-pomorskie', label: 'Kujawsko-pomorskie' },
  { id: 'lubelskie', label: 'Lubelskie' },
  { id: 'lubuskie', label: 'Lubuskie' },
  { id: 'lodzkie', label: 'Łódzkie' },
  { id: 'malopolskie', label: 'Małopolskie' },
  { id: 'mazowieckie', label: 'Mazowieckie' },
  { id: 'opolskie', label: 'Opolskie' },
  { id: 'podkarpackie', label: 'Podkarpackie' },
  { id: 'podlaskie', label: 'Podlaskie' },
  { id: 'pomorskie', label: 'Pomorskie' },
  { id: 'slaskie', label: 'Śląskie' },
  { id: 'swietokrzyskie', label: 'Świętokrzyskie' },
  { id: 'warminsko-mazurskie', label: 'Warmińsko-mazurskie' },
  { id: 'wielkopolskie', label: 'Wielkopolskie' },
  { id: 'zachodniopomorskie', label: 'Zachodniopomorskie' },
]

export const officeNFZArray = [
  { id: 'dolnoslaski-oddzial-wojewodzki', label: 'Dolnośląski Oddział Wojewódzki NFZ' },
  { id: 'kujawsko-pomorski-oddzial-wojewodzki', label: 'Kujawsko-Pomorski Oddział Wojewódzki NFZ' },
  { id: 'lubelski-oddzial-wojewodzki', label: 'Lubelski Oddział Wojewódzki NFZ' },
  { id: 'lubuski-oddzial-wojewodzki', label: 'Lubuski Oddział Wojewódzki NFZ' },
  { id: 'lodzki-oddzial-wojewodzki', label: 'Łódzki Oddział Wojewódzki NFZ' },
  { id: 'malopolski-oddzial-wojewodzki', label: 'Małopolski Oddział Wojewódzki NFZ' },
  { id: 'mazowiecki-oddzial-wojewodzki', label: 'Mazowiecki Oddział Wojewódzki NFZ' },
  { id: 'opolski-oddzial-wojewodzki', label: 'Opolski Oddział Wojewódzki NFZ' },
  { id: 'podkarpacki-oddzial-wojewodzki', label: 'Podkarpacki Oddział Wojewódzki NFZ' },
  { id: 'podlaski-oddzial-wojewodzki', label: 'Podlaski Oddział Wojewódzki NFZ' },
  { id: 'pomorski-oddzial-wojewodzki', label: 'Pomorski Oddział Wojewódzki NFZ' },
  { id: 'slaski-oddzial-wojewodzki', label: 'Śląski Oddział Wojewódzki NFZ' },
  { id: 'swietokrzyski-oddzial-wojewodzki', label: 'Świętokrzyski Oddział Wojewódzki NFZ' },
  { id: 'warminsko-mazurski-oddzial-wojewodzki', label: 'Warmińsko-Mazurski Oddział Wojewódzki NFZ' },
  { id: 'wielkopolski-oddzial-wojewodzki', label: 'Wielkopolski Oddział Wojewódzki NFZ' },
  { id: 'zachodniopomorski-oddzial-wojewodzki', label: 'Zachodniopomorski Oddział Wojewódzki NFZ' },
]

export const translatePropsObj = {
  street: 'Ulica', // Wartość pola
  buildingNumber: 'Numer domu', // Wartość pola
  apartmentNumber: 'Numer lokalu', // Wartość pola
  city: 'Miasto', // Wartość pola
  postalCode: 'Kod pocztowy', // Wartość pola
  nationality: 'Obywatelstwo', // Wartość pola
  pesel: 'PESEL', // Wartość pola
  passportNumber: 'Numer paszportu', // Wartość pola
  bankAccountNumber: 'Numer konta', // Wartość pola
  taxOffice: 'Urząd Skarbowy', // Wartość pola
  voivodship: 'Województwo', // Wartość pola
  commune: 'Gmina', // Wartość pola
  // Ankieta
  isStudentUnder26: 'Student/Uczeń < 26', // True - Tak, False - Nie, puste: ''
  hasOtherJobAndLessThenMinimumWage: 'Zatrudniony / podstawa mniejsza', // True - Tak, False - Nie, puste: ''
  hasOtherJobAndMoreThenMinimumWage: 'Zatrudniony / podstawa większa', // True - Tak, False - Nie, puste: ''
  hasBusinessAndContributions60: 'Działalność / składki 60', // True - Tak, False - Nie, puste: ''
  hasBusinessAndContributions30: 'Działalność / składki 30', // True - Tak, False - Nie, puste: ''
  hasBussinesAndSameServices: 'Działakność / usługi się pokrywają', // True - Tak, False - Nie, puste: ''
  hasOtherJobButIsOnUnpaidOrParentalLeave: 'Inna praca / urlop', // True - Tak, False - Nie, puste: ''
  hasOtherJobButIsOnMaternityLeave: 'Inna praca / urlop macierzyński', // True - Tak, False - Nie, puste: ''
  hasNoOtherSocialInsurance: 'Brak innych tytułów do ubezpieczeń', // True - Tak, False - Nie, puste: ''
  isRetired: 'Emeryt / rencista', // True - Tak, False - Nie, puste: ''
  hasDisability: 'Legitymacja o niepełnosprawności', // True - Tak, False - Nie, puste: ''
  hasDisabilityOther: 'Stopień niepełnosprawności', // Wartość pola
  healthContribution: 'Składka zdrowotna', // True - Tak, False - Nie, puste: ''
  otherCircumstances: 'Inne okoliczności na ubezpieczenie', // True - Tak, False - Nie, puste: ''
  otherCircumstancesOther: 'Okoliczności', // Wartość pola
  exemptionPIT: 'Zwolnienie z PIT', // True - Tak, False - Nie, puste: ''
  taxResidenceCertificate: 'Certyfikat rezydencji podatkowej', // True - Tak, False - Nie, puste: ''
  taxResidenceCertificateStartDate: 'Certyfikat od', // Wartość pola ale to jest forma daty wiec pewnie DD-MM-YYYY
  taxResidenceCertificateEndDate: 'Certyfikat do', // Wartość pola ale to jest forma daty wiec pewnie DD-MM-YYYY
  officeNFZ: 'Oddział NFZ', // Wartość pola - podsyłam mapowanie którego używam
  termsChanges: 'Zgoda - informacje o zmianach', // True - Tak, False - Nie, puste: ''
  termsRodo: 'Zgoda - rodo', // True - Tak, False - Nie, puste: ''
}

export const monthsOptions = [
  { value: 1, label: 'styczeń' },
  { value: 2, label: 'luty' },
  { value: 3, label: 'marzec' },
  { value: 4, label: 'kwiecień' },
  { value: 5, label: 'maj' },
  { value: 6, label: 'czerwiec' },
  { value: 7, label: 'lipiec' },
  { value: 8, label: 'sierpień' },
  { value: 9, label: 'wrzesień' },
  { value: 10, label: 'październik' },
  { value: 11, label: 'listopad' },
  { value: 12, label: 'grudzień' },
]
