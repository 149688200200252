import { extendTheme } from '@mui/joy'
import { createTheme } from '@mui/material'

export const materialTheme = createTheme()
// --joy-palette-neutral-plainColor
const myAppTheme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        text: {
          primary: '#d1d1d1',
          secondary: '#d1d1d1',
          tertiary: '#d1d1d1',
        },
      },
    },
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: {
          background: 'var(--joy-palette-background-body)',
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          background: 'var(--joy-palette-background-body)',
        },
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: {
          background: 'var(--joy-palette-background-body)',
        },
      },
    },
  },
})

export default myAppTheme
