import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Navigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Button, Typography } from '@mui/joy'
import { usersService } from 'services/usersService'
import { cleanupRequestStatus, getUserDetails, updateCurrentUserProp } from 'features/usersSlice'
import { CheckCircle, Warning } from '@mui/icons-material'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import MainLayout from 'components/templates/MainLayout'
import PageLoader from 'components/PageLoader'
import CustomPageTabs from 'components/CustomTabs'
import PersonalDataSubView from 'views/common/PersonalDataSubView'
import AdditionalDataSubView from 'views/common/AdditionalDataSubView'
import { handleErrorMessage } from 'utils/helpers'

const AdminUsersDetailsView = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { userId } = useParams()
  const { currentUser, loading, error } = useSelector((s) => s.users)

  useEffect(() => {
    if (userId) dispatch(getUserDetails(userId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const handleActivate = (userId) => {
    usersService
      .adminUsersActivate(userId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytownik został aktywowany', { variant: 'success' })
        dispatch(updateCurrentUserProp({ prop: 'isAccountConfirmed', value: true }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
  }

  // const handleDeactivate = (userId) => {
  //   console.log('Dezaktywuj', userId)
  //   enqueueSnackbar('Ta funkcja nie została jeszcze zaimplementowana', { variant: 'error' })
  // }

  if (!userId) return <Navigate to={routes.adminUsers} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ flex: 1, width: '100%' }}>
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Breadcrumbs items={[{ label: 'Użytkownicy', to: routes.adminUsers }, { label: 'Podgląd użytkownik' }]} />
          {currentUser && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'start', sm: 'center' },
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                  Użytkownik:&nbsp;
                  <Typography color="primary">
                    {currentUser.firstName} {currentUser.lastName}
                  </Typography>
                </Typography>
                {!currentUser.isAccountConfirmed && (
                  <Button
                    onClick={() => handleActivate(currentUser.id)}
                    color="primary"
                    startDecorator={<CheckCircle />}
                  >
                    Aktywuj użytkownika
                  </Button>
                )}
              </Box>
              <CustomPageTabs
                tabs={[
                  {
                    label: 'Dane podstawowe',
                    slug: 'dane-podstawowe',
                    component: <PersonalDataSubView userInfo={currentUser} mode="editUser" />,
                  },
                  {
                    label: 'Dane do umowy',
                    slug: 'dane-do-umowy',
                    component: <AdditionalDataSubView userInfo={currentUser} mode="editUser" />,
                  },
                ]}
              />
              {error && (
                <Alert color="danger" variant="soft" startDecorator={<Warning />}>
                  {error}
                </Alert>
              )}
            </>
          )}
        </Box>
      </Box>
    </MainLayout>
  )
}

export default AdminUsersDetailsView
