import { useSelector } from 'react-redux'
import { Box, ListItemDecorator, Typography } from '@mui/joy'
import { ErrorOutline } from '@mui/icons-material'
import { ROLES } from 'utils/config'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import PersonalDataSubView from '../common/PersonalDataSubView'
import AdditionalDataSubView from '../common/AdditionalDataSubView'
import ChangePasswordSubView from '../common/ChangePasswordSubView'
import CustomPageTabs from 'components/CustomTabs'

const ProfileView = () => {
  const { userInfo } = useSelector((s) => s.auth)

  const isUserWithNoBillingDataFilled = userInfo.role === ROLES.user.value && !userInfo.isBillingInfoComplete

  return (
    <MainLayout>
      <Box sx={{ flex: 1, width: '100%' }}>
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Breadcrumbs items={[{ label: 'Mój Profil' }]} />
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            Mój profil:&nbsp;
            <Typography color="primary">
              {userInfo.firstName} {userInfo.lastName}
              <Typography
                level="title-md"
                textColor="var(--joy-palette-success-plainColor)"
                sx={{ fontFamily: 'monospace', opacity: '50%' }}
              >
                &nbsp;({ROLES[userInfo.role.toLowerCase()]?.label})
              </Typography>
            </Typography>
          </Typography>
          <CustomPageTabs
            initialTabSlug={isUserWithNoBillingDataFilled ? 'dane-do-umowy' : 'dane-podstawowe'}
            tabs={[
              {
                label: 'Dane podstawowe',
                slug: 'dane-podstawowe',
                component: <PersonalDataSubView userInfo={userInfo} mode="profile" />,
              },
              userInfo.role === ROLES.user.value
                ? {
                    label: isUserWithNoBillingDataFilled ? (
                      <>
                        Dane do umowy
                        <ListItemDecorator>
                          <ErrorOutline color="error" fontSize="small" />
                        </ListItemDecorator>
                      </>
                    ) : (
                      'Dane do umowy'
                    ),
                    slug: 'dane-do-umowy',
                    component: <AdditionalDataSubView userInfo={userInfo} mode="profile" />,
                  }
                : false,
              {
                label: 'Zmień hasło',
                slug: 'zmien-haslo',
                component: <ChangePasswordSubView userInfo={userInfo} mode="profile" />,
              },
            ].filter(Boolean)}
          />
        </Box>
      </Box>
    </MainLayout>
  )
}

export default ProfileView
