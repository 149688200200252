import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { usersService } from 'services/usersService'
import { addComputedFullName, sortByDate } from 'utils/helpers'

const initialState = {
  usersBillings: [],
  usersBillingsDetails: null,
  billings: [],
  loading: false,
  error: null,
  success: false,
}

export const getUsersBillings = createAsyncThunk('users-list-billings', async (data = {}, { rejectWithValue }) => {
  try {
    const response = await usersService.adminUsersBillings(data)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const getUserBillingsDetails = createAsyncThunk('users-billings-details', async (data, { rejectWithValue }) => {
  try {
    const response = await usersService.adminUserBillingsDetails(data)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const getUserBillings = createAsyncThunk('user-billings', async (_, { rejectWithValue }) => {
  try {
    const response = await usersService.usersBillings()
    return response.data
  } catch (error) {
    if (error.response && error.response.data.message) return rejectWithValue(error.response.data.message)
    return rejectWithValue(error.message)
  }
})

export const billingsSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    clean: () => {
      return initialState
    },
    cleanupRequestStatus: (state) => {
      state.error = false
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      // Get users billings list
      .addCase(getUsersBillings.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUsersBillings.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.usersBillings = payload.map(addComputedFullName).sort((a, b) => {
          if (a.computedFullName < b.computedFullName) return -1
          if (a.computedFullName > b.computedFullName) return 1
          return 0
        })
      })
      .addCase(getUsersBillings.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      // Get users billings details
      .addCase(getUserBillingsDetails.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUserBillingsDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.usersBillingsDetails = { ...payload, tasks: sortByDate(payload.tasks) }
      })
      .addCase(getUserBillingsDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      // Get billings for logged user
      .addCase(getUserBillings.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUserBillings.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.billings = payload
      })
      .addCase(getUserBillings.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export const { clean, cleanupRequestStatus } = billingsSlice.actions

export default billingsSlice.reducer
