import { CustomYup } from 'validations/CustomYup'

const newTaskSchema = CustomYup.object().shape({
  lp: CustomYup.string().required(),
  date: CustomYup.string().required(),
  hours: CustomYup.string().required(),
  hoursCount: CustomYup.string().required(),
  localization: CustomYup.string().required(),
  notes: CustomYup.string(),
})

const addTasksSchema = CustomYup.object().shape({
  tasks: CustomYup.array().of(newTaskSchema).min(1, 'Musisz uzupełnic przynajmniej 1 zadanie.'),
})

export default addTasksSchema
