import customAxios, { customAxiosFile } from 'services/customAxios'

const urls = {
  projects: 'projects',
  projectsDetails: 'projects/:projectId',

  adminAddProject: 'admin/projects',
  adminEditProject: 'admin/projects/:projectId',
  adminDeleteProject: 'admin/projects/:projectId',

  adminAddProjectTasks: 'admin/projects/:projectId/tasks',
  adminaApproveProjectTask: 'admin/projects/:projectId/tasks/:taskId/approve',

  adminImportProjectTasks: 'admin/projects/:projectId/tasks/import-csv',
  adminTaskDetails: 'admin/projects/:projectId/tasks/:taskId',
  adminTaskReserveUsers: 'admin/projects/:projectId/tasks/:taskId/reserve-list',
  adminRemoveTaskReserveUser: 'admin/projects/:projectId/tasks/:taskId/reserve-list/:userId',

  adminAssignProjectTaskUser: 'admin/projects/:projectId/tasks/:taskId/assign/:userId',
  adminUnassignProjectTaskUser: 'admin/projects/:projectId/tasks/:taskId/unassign/:userId',

  adminUpdateProjectTask: 'admin/projects/:projectId/tasks/:taskId',
  adminDeleteProjectTask: 'admin/projects/:projectId/tasks/:taskId',
  adminDownloadImportSample: 'admin/projects/sample-csv',

  userTaskApply: 'projects/:projectId/tasks/:taskId/apply',
  userTaskApplyReserve: 'projects/:projectId/tasks/:taskId/apply-reserve',
}

const getProjects = ({ params = null }) =>
  customAxios({
    method: 'get',
    url: urls.projects,
    params,
  })

const getProjectDetails = (projectId) =>
  customAxios({
    method: 'get',
    url: urls.projectsDetails.replace(':projectId', projectId),
  })

const deleteProject = (projectId) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProject.replace(':projectId', projectId),
  })

const getTaskDetails = (projectId, taskId) =>
  customAxios({
    method: 'get',
    url: urls.adminTaskDetails.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const getTaskReserveUsers = (projectId, taskId) =>
  customAxios({
    method: 'get',
    url: urls.adminTaskReserveUsers.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const adminRemoveProjectTaskReserveUser = (projectId, taskId, userId) =>
  customAxios({
    method: 'delete',
    url: urls.adminRemoveTaskReserveUser
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':userId', userId),
  })

const adminAssignProjectTaskUser = (projectId, taskId, userId) =>
  customAxios({
    method: 'post',
    url: urls.adminAssignProjectTaskUser
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':userId', userId),
  })

const adminUnassignProjectTaskUser = (projectId, taskId, userId) =>
  customAxios({
    method: 'delete',
    url: urls.adminUnassignProjectTaskUser
      .replace(':projectId', projectId)
      .replace(':taskId', taskId)
      .replace(':userId', userId),
  })

const createProject = (data) =>
  customAxios({
    method: 'post',
    url: urls.adminAddProject,
    data,
  })

const updateProject = (projectId, data) =>
  customAxios({
    method: 'put',
    url: urls.adminEditProject.replace(':projectId', projectId),
    data,
  })

const addProjectTasks = (projectId, data) =>
  customAxios({
    method: 'post',
    url: urls.adminAddProjectTasks.replace(':projectId', projectId),
    data,
  })

const importProjectTasks = (projectId, data) =>
  customAxiosFile({
    method: 'post',
    url: urls.adminImportProjectTasks.replace(':projectId', projectId),
    data,
  })

const userTaskApply = (projectId, taskId) =>
  customAxios({
    method: 'post',
    url: urls.userTaskApply.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const userTaskApplyReserve = (projectId, taskId) =>
  customAxios({
    method: 'post',
    url: urls.userTaskApplyReserve.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const adminApproveProjectTask = (projectId, taskId) =>
  customAxios({
    method: 'post',
    url: urls.adminaApproveProjectTask.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const adminUpdateProjectTask = (projectId, taskId, data) =>
  customAxios({
    method: 'put',
    url: urls.adminUpdateProjectTask.replace(':projectId', projectId).replace(':taskId', taskId),
    data,
  })

const deleteProjectTask = (projectId, taskId) =>
  customAxios({
    method: 'delete',
    url: urls.adminDeleteProjectTask.replace(':projectId', projectId).replace(':taskId', taskId),
  })

const downloadImportSample = () =>
  customAxios({
    method: 'get',
    url: urls.adminDownloadImportSample,
    responseType: 'blob',
  })

export const projectService = {
  getProjects,
  getProjectDetails,
  deleteProject,
  getTaskDetails,
  getTaskReserveUsers,
  adminRemoveProjectTaskReserveUser,
  adminAssignProjectTaskUser,
  adminUnassignProjectTaskUser,
  createProject,
  updateProject,
  addProjectTasks,
  importProjectTasks,
  userTaskApply,
  userTaskApplyReserve,
  adminApproveProjectTask,
  adminUpdateProjectTask,
  deleteProjectTask,
  downloadImportSample,
}
