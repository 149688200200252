import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Typography } from '@mui/joy'
import Breadcrumbs from 'components/Breadcrumbs'
import BaseTable from 'components/tables/BaseTable'
import FilterSearch from 'components/tables/FilterSearch'
import FilterSelect from 'components/tables/FilterSelect'
import FiltersWrapper from 'components/tables/FiltersWrapper'
// import TablePaginationDesktop from 'components/tables/TablePaginationDesktop'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import routes from 'routes'
import { cleanupRequestStatus, getUsers } from 'features/usersSlice'
import { ROLES } from 'utils/config'
import PageLoader from 'components/PageLoader'
import { Warning } from '@mui/icons-material'
import { usersService } from 'services/usersService'
import { useSnackbar } from 'notistack'
import { handleErrorMessage } from 'utils/helpers'
import DeleteModal from 'components/DeleteModal'

const headers = [
  { label: 'Imię', propName: 'firstName' },
  { label: 'Naziwsko', propName: 'lastName' },
  { label: 'E-mail', propName: 'email' },
  { label: 'Telefon', propName: 'phoneNumber' },
  { label: 'Zweryfikowano e-mail', propName: 'isEmailVerified', type: 'check' },
  { label: 'Zatwierdzono', propName: 'isAccountConfirmed', type: 'check' },
  { label: 'Dane do umowy', propName: 'isBillingInfoComplete', type: 'check' },
]

const AdminUsersView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const [deleteUserObject, setDeleteUserObject] = useState(false)

  const [search, setSearch] = useState('')
  const [role, setRole] = useState(ROLES.user.value)
  const { users, loading, error } = useSelector((s) => s.users)

  const pagination = usePagination()

  const params = useMemo(
    () => ({
      role: role || null,
      search: search || null,
    }),
    [search, role]
  )

  useEffect(() => {
    dispatch(getUsers({ params }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, params])

  const handleGoToUserPage = (userId) => navigate(routes.adminUsersDetails.replace(':userId', userId))

  const handleDeleteUser = () => {
    setInnerLoading(true)

    usersService
      .deleteUser(deleteUserObject.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytkownik został usunięty', { variant: 'success' })
        setDeleteUserObject(false)
        dispatch(getUsers({ params }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <DeleteModal
        open={deleteUserObject}
        handleDelete={handleDeleteUser}
        loading={innerLoading}
        setObject={setDeleteUserObject}
      >
        Czy jesteś pewny, ze chcesz usunąć użytkownika:{' '}
        <Typography color="primary" fontWeight={500}>
          {deleteUserObject.email}
        </Typography>
      </DeleteModal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Użytkownicy' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Użytkownicy
        </Typography>
      </Box>

      <FiltersWrapper
        renderSearch={<FilterSearch label="Wyszukiwanie użytkowników" value={search} setValue={setSearch} />}
        renderFilters={
          <FilterSelect
            label="Rola"
            placeholder="Filtruj po roli"
            options={[
              { value: ROLES.user.value, label: ROLES.user.label },
              { value: ROLES.admin.value, label: ROLES.admin.label },
            ]}
            value={role}
            setValue={setRole}
          />
        }
      />

      <BaseTable
        headers={headers}
        data={users}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        actions={[
          { label: 'Zobacz', func: (el) => handleGoToUserPage(el.id) },
          { label: 'Usuń', color: 'danger', divider: true, func: (el) => setDeleteUserObject(el) },
        ]}
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}
    </MainLayout>
  )
}

export default AdminUsersView
