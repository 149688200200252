import IconButton from '@mui/joy/IconButton'
import { useColorScheme } from '@mui/joy/styles'

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import LightModeIcon from '@mui/icons-material/LightMode'

// eslint-disable-next-line react/prop-types
const ColorSchemeToggle = ({ onClick, sx, ...other }) => {
  const { mode, setMode } = useColorScheme()

  return (
    <IconButton
      data-screenshot="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      {...other}
      onClick={(event) => {
        if (mode === 'light') setMode('dark')
        else setMode('light')
        onClick?.(event)
      }}
      sx={[
        mode === 'dark'
          ? { '& > *:first-of-type': { display: 'none' } }
          : { '& > *:first-of-type': { display: 'initial' } },
        mode === 'light' ? { '& > *:last-child': { display: 'none' } } : { '& > *:last-child': { display: 'initial' } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <DarkModeRoundedIcon />
      <LightModeIcon />
    </IconButton>
  )
}

export default ColorSchemeToggle
