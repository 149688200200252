import { CustomYup } from 'validations/CustomYup'
import { ibanFieldSchema, peselFieldSchema, polishOrUkrainianPassportFieldSchema, zipCodeFieldSchema } from '../helpers'

const additionalDataSchema = CustomYup.object().shape({
  street: CustomYup.string(),
  buildingNumber: CustomYup.string().required(),
  apartmentNumber: CustomYup.string(),
  city: CustomYup.string().required(),
  postalCode: zipCodeFieldSchema.required(),
  nationality: CustomYup.object({
    id: CustomYup.string().required(),
    label: CustomYup.string().required(),
  }).required(),
  pesel: CustomYup.mixed().when('nationality', {
    is: (nationality) => nationality?.id === 'PL' || nationality?.id === 'UA',
    then: () =>
      CustomYup.mixed().when('nationality', {
        is: (nationality) => nationality?.id === 'PL',
        then: () => peselFieldSchema.required(),
        otherwise: () => peselFieldSchema,
      }),
    otherwise: () =>
      CustomYup.string()
        .test('format', 'Wybrana narodowość nie posiada numeru PESEL.', (value) => {
          if (value === '' || value === undefined) return true
          return false
        })
        .nullable(),
  }),
  passportNumber: CustomYup.mixed().when('nationality', {
    is: (nationality) => nationality?.id !== 'PL',
    then: () => polishOrUkrainianPassportFieldSchema.required(),
    otherwise: () => CustomYup.string().nullable(),
  }),
  bankAccountNumber: ibanFieldSchema.required(),
  taxOffice: CustomYup.string().required(),
  voivodship: CustomYup.object({
    id: CustomYup.string().required(),
    label: CustomYup.string().required(),
  }).required(),
  commune: CustomYup.string().required(),
  // Ankieta
  isStudentUnder26: CustomYup.bool().nullable(),
  hasOtherJobAndLessThenMinimumWage: CustomYup.bool().nullable(),
  hasOtherJobAndMoreThenMinimumWage: CustomYup.bool().nullable(),
  hasBusinessAndContributions60: CustomYup.bool().nullable(),
  hasBusinessAndContributions30: CustomYup.bool().nullable(),
  hasBussinesAndSameServices: CustomYup.bool().nullable(),
  hasOtherJobButIsOnUnpaidOrParentalLeave: CustomYup.bool().nullable(),
  hasOtherJobButIsOnMaternityLeave: CustomYup.bool().nullable(),
  hasNoOtherSocialInsurance: CustomYup.bool().nullable(),
  isRetired: CustomYup.bool().nullable(),
  hasDisability: CustomYup.bool().nullable(),
  hasDisabilityOther: CustomYup.mixed().when('hasDisability', {
    is: (hasDisability) => hasDisability === true,
    then: () => CustomYup.string().required(),
    otherwise: () => CustomYup.string(),
  }),
  healthContribution: CustomYup.bool().oneOf([true, false], 'To pole jest wymagane.').required(),
  otherCircumstances: CustomYup.bool().nullable(),
  otherCircumstancesOther: CustomYup.mixed().when('otherCircumstances', {
    is: (otherCircumstances) => otherCircumstances === true,
    then: () => CustomYup.string().required(),
    otherwise: () => CustomYup.string(),
  }),
  exemptionPIT: CustomYup.bool().oneOf([true, false], 'To pole jest wymagane.').required(),
  taxResidenceCertificate: CustomYup.mixed().when('nationality', {
    is: (nationality) => nationality?.id !== 'PL',
    then: () => CustomYup.bool().oneOf([true, false], 'To pole jest wymagane.').required(),
    otherwise: () => CustomYup.string().nullable(),
  }),
  taxResidenceCertificateStartDate: CustomYup.mixed().when('taxResidenceCertificate', {
    is: (taxResidenceCertificate) => taxResidenceCertificate === true,
    then: () => CustomYup.string().required(),
    otherwise: () => CustomYup.string().nullable(),
  }),
  taxResidenceCertificateEndDate: CustomYup.mixed().when('taxResidenceCertificate', {
    is: (taxResidenceCertificate) => taxResidenceCertificate === true,
    then: () => CustomYup.string().required(),
    otherwise: () => CustomYup.string().nullable(),
  }),
  officeNFZ: CustomYup.object({
    id: CustomYup.string().required(),
    label: CustomYup.string().required(),
  }).required(),
  termsChanges: CustomYup.bool().oneOf([true], 'Pole jest wymagane.'),
  termsRodo: CustomYup.bool().oneOf([true], 'Pole jest wymagane.'),
})

export default additionalDataSchema
