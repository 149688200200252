import { useEffect } from 'react'
import { useFormik } from 'formik'
import parseHtml from 'html-react-parser'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Button, Divider, Link, Typography, Stack } from '@mui/joy'
import { cleanupRequestStatus, loginUser } from 'features/authSlice'
import { Info, Warning } from '@mui/icons-material'
import routes from 'routes'
import loginSchema from 'validations/auth/loginSchema'
import CustomInputControl from 'components/forms/CustomInputControl'
import AuthLayout from 'components/templates/AuthLayout'
import PageLoader from 'components/PageLoader'
import { ROLES } from 'utils/config'

const LoginView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()

  const { loading, error, userInfo } = useSelector((s) => s.auth)

  useEffect(() => {
    return () => dispatch(cleanupRequestStatus())
  }, [])

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (!userInfo) return
    if (userInfo.role === ROLES.user.value) navigate(routes.userMyTasks)
    if (userInfo.role === ROLES.admin.value) navigate(routes.adminProjects)
  }, [navigate, userInfo])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => dispatch(loginUser(values)),
  })

  if (userInfo) return <PageLoader />

  return (
    <AuthLayout>
      <Stack sx={{ gap: 4, mb: 2 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography component="h1" level="h3">
            Logowanie
          </Typography>
          <Typography level="body-sm">
            Jesteś nowy?{' '}
            <Link component={RouterLink} to={routes.register} level="title-sm">
              Zapisz się!
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={(theme) => ({
          [theme.getColorSchemeSelector('light')]: {
            color: { xs: '#FFF', md: 'text.tertiary' },
          },
        })}
      >
        lub
      </Divider>
      <Stack sx={{ gap: 4, mt: 2 }}>
        {error && (
          <Alert color="danger" variant="soft" startDecorator={<Warning />}>
            {error}
          </Alert>
        )}
        {state?.message && (
          <Alert color="success" variant="soft" startDecorator={<Info />}>
            <Typography level="body-sm">{parseHtml(state.message)}</Typography>
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <CustomInputControl name="email" label="E-mail" formik={formik} fullWidth />
          <CustomInputControl name="password" label="Hasło" formik={formik} fullWidth type="password" />
          <Stack sx={{ gap: 4, mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                // justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Link level="title-sm" component={RouterLink} to={routes.resetPassword}>
                Zapomniałeś hasła?
              </Link>
            </Box>
            <Button type="submit" fullWidth disabled={loading} loading={loading}>
              Zaloguj
            </Button>
          </Stack>
        </form>
      </Stack>
    </AuthLayout>
  )
}

export default LoginView
