import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Link, useNavigate } from 'react-router-dom'
import { PostAdd, Warning } from '@mui/icons-material'
import { Alert, Box, Button, Typography } from '@mui/joy'
import { cleanupRequestStatus, getProjects } from 'features/projectsSlice'
import { projectService } from 'services/projectsService'
import { handleErrorMessage } from 'utils/helpers'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import BaseTable from 'components/tables/BaseTable'
import FilterSearch from 'components/tables/FilterSearch'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import DeleteModal from 'components/DeleteModal'

const headers = [
  {
    label: 'Nazwa projektu',
    propName: 'name',
    type: 'route',
    route: routes.adminProjectsDetails,
    routeReplace: ':projectId',
  },
  { label: 'Lokalizacja', propName: 'location', rowClassName: 'text-center', className: 'text-center' },
  {
    label: 'Stawka brutto',
    propName: 'hourlyRate',
    type: 'moneyRate',
    rowClassName: 'text-center',
    className: 'text-center',
  },
]

const AdminProjectsView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const [deleteProjectObject, setDeleteProjectObject] = useState(false)

  const [search, setSearch] = useState('')

  const { projects, loading, error } = useSelector((s) => s.projects)

  const pagination = usePagination()

  const handleGoToProjectPage = (projectId) => navigate(routes.adminProjectsDetails.replace(':projectId', projectId))
  const handleGoToProjectEditPage = (projectId) => navigate(routes.adminProjectsEdit.replace(':projectId', projectId))

  const params = useMemo(
    () => ({
      search: search || null,
    }),
    [search]
  )

  useEffect(() => {
    dispatch(getProjects({ params }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, params])

  const handleDeleteProject = () => {
    setInnerLoading(true)

    projectService
      .deleteProject(deleteProjectObject.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Projekt został usunięty', { variant: 'success' })
        setDeleteProjectObject(false)
        dispatch(getProjects())
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <DeleteModal
        open={deleteProjectObject}
        handleDelete={handleDeleteProject}
        loading={innerLoading}
        setObject={setDeleteProjectObject}
      >
        Czy jesteś pewny, ze chcesz usunąć projekt:{' '}
        <Typography color="primary" fontWeight={500}>
          {deleteProjectObject.name}
        </Typography>
      </DeleteModal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Projekty' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projekty
        </Typography>
        <Button
          component={Link}
          to={routes.adminProjectsCreate}
          color="primary"
          size="sm"
          startDecorator={<PostAdd fontSize="small" />}
        >
          Dodaj projekt
        </Button>
      </Box>

      <FiltersWrapper
        renderSearch={<FilterSearch label="Wyszukiwanie projektu" value={search} setValue={setSearch} />}
      />

      <BaseTable
        headers={headers}
        data={projects}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        actions={[
          { label: 'Zobacz projekt', func: (el) => handleGoToProjectPage(el.id) },
          { label: 'Edytuj projekt', color: 'warning', divider: true, func: (el) => handleGoToProjectEditPage(el.id) },
          { label: 'Usuń projekt', color: 'danger', divider: true, func: (el) => setDeleteProjectObject(el) },
        ]}
        noDataText="Brak projektów do wyświetlenia."
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}

      {/* <TablePaginationDesktop pagination={pagination} /> */}
    </MainLayout>
  )
}

export default AdminProjectsView
