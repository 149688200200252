import { useState } from 'react'

const initialPag = {
  count: 100,
  totalPages: 5,
  currentPage: 2,
  nextPage: 3,
  previousPage: 1,
  page: 2,
  perPage: 20,
}

export default (initialPagination = initialPag) => {
  const [pag, setPagination] = useState(initialPagination)
  const [perPage, setPerPage] = useState(initialPag.perPage)
  const [page, setPage] = useState(initialPag.page)

  const handlePerPage = (val) => {
    setPerPage(Number(val))
    setPage(1)
  }

  return { values: { ...pag, page, perPage }, setPerPage: handlePerPage, setPage, setPagination }
}
