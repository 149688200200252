import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from 'features/authSlice'
import globalRouter from 'globalRouter'
import Router from 'views/Router'

const App = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  globalRouter.navigate = navigate

  const { userToken, userInfo } = useSelector((s) => s.auth)

  useEffect(() => {
    if (!userInfo && userToken) dispatch(getMe())
  }, [userToken, dispatch])

  return <Router />
}

export default App
