import customAxios from 'services/customAxios'

const urls = {
  exportUsersTasks: 'admin/users/export-tasks',
  exportUsersContractData: 'admin/users/export-agreement-data',
}

const downloadExportUserTasks = () =>
  customAxios({
    method: 'get',
    url: urls.exportUsersTasks,
    responseType: 'blob',
  })

const downloadExportUserContractData = () =>
  customAxios({
    method: 'get',
    url: urls.exportUsersContractData,
    responseType: 'blob',
  })

export const adminService = {
  downloadExportUserTasks,
  downloadExportUserContractData,
}
