import { NIP, PESEL, PassportNumber } from 'id-pl'
import IBAN from 'iban'
import { CustomYup } from 'validations/CustomYup'

const NIP_EXCLUDE = [
  '0000000000',
  '1111111111',
  '2222222222',
  '3333333333',
  '4444444444',
  '5555555555',
  '6666666666',
  '7777777777',
  '8888888888',
  '9999999999',
]
const ukrainianPassportRegex = /^[0-9A-Z]{2}[0-9]{6}$/

// const placeNameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ&-\s\d]+$/
// const gusPlaceNameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ&()-\s\d]+$/
const nameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/
const surnameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/
const zipCodeRegex = /^\d{2}-\d{3}$/
const phoneRegex = /^\d{9}$/
// const polishLettersRegex = /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+/

export const validateNip = (nip) => {
  if (NIP_EXCLUDE.includes(nip)) return false
  return NIP.isValid(nip)
}

export const validatePesel = (pesel) => PESEL.isValid(pesel)
export const validatePassport = (passport) => PassportNumber.isValid(passport)

// Ready fields
export const firstNameFieldSchema = CustomYup.string().matches(nameRegex, 'Niedozwolone znaki')
export const lastNameFieldSchema = CustomYup.string().matches(surnameRegex, 'Niedozwolone znaki')
export const emailFieldSchema = CustomYup.string().email()
export const passwordFieldSchema = CustomYup.string().min(6, 'Hasło musi składac się z minimum 6 znaków.')
export const phoneFieldSchema = CustomYup.string()
  .matches(phoneRegex, {
    message: 'Niepoprawny numeru telefonu',
    excludeEmptyString: true,
  })
  .typeError('Niepoprawny numeru telefonu')

export const booleanFieldSchema = CustomYup.bool()
export const zipCodeFieldSchema = CustomYup.string()
  .matches(zipCodeRegex, {
    message: 'Niepoprawny format kodu pocztowego',
    excludeEmptyString: true,
  })
  .typeError('Niepoprawny format kodu pocztowego')

export const peselFieldSchema = CustomYup.string().test('format', 'Niepoprawny numer PESEL.', (value) => {
  if (value == undefined) return true
  return validatePesel(String(value))
})
export const passportFieldSchema = CustomYup.string().test('format', 'Niepoprawny numer paszportu.', (value) =>
  validatePassport(String(value))
)
export const polishOrUkrainianPassportFieldSchema = CustomYup.string().test(
  'format',
  'Niepoprawny numer paszportu.',
  (value) => {
    return validatePassport(String(value)) || ukrainianPassportRegex.test(String(value))
  }
)
export const ibanFieldSchema = CustomYup.string().test('format', 'Niepoprawny numer IBAN.', (value) =>
  Boolean(value && (IBAN.isValid(value) || IBAN.isValid(`PL${value}`)))
)
