import { Navigate, Link as RouterLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Link, Typography, Stack, LinearProgress } from '@mui/joy'
import WarningIcon from '@mui/icons-material/Warning'
import AuthLayout from 'components/templates/AuthLayout'
import routes from 'routes'
import { useEffect } from 'react'
import { cleanupRequestStatus, confirmEmail } from 'features/authSlice'

const ConfirmEmaiView = () => {
  const dispatch = useDispatch()
  const { token } = useParams()

  const { loading, error, success } = useSelector((s) => s.auth)

  useEffect(() => {
    dispatch(confirmEmail(token))
    return () => dispatch(cleanupRequestStatus())
  }, [])

  if (success) return <Navigate to={routes.login} state={{ message: 'Adres e-mail został zweryfikowany.' }} />

  if (!token) return <Navigate to={routes.login} />

  return (
    <AuthLayout>
      <Stack sx={{ gap: 4 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography component="h1" level="h3">
            Weryfikowanie adresu e-mail
          </Typography>
          {error && (
            <Typography level="body-sm">
              <Link component={RouterLink} to={routes.login} level="title-sm">
                Wróć do logowania
              </Link>
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ gap: 4, mt: 2 }}>
        {error && (
          <Alert color="danger" variant="soft" startDecorator={<WarningIcon />}>
            {error}
          </Alert>
        )}
        {loading && <LinearProgress />}
      </Stack>
    </AuthLayout>
  )
}

export default ConfirmEmaiView
