import { MoreHorizRounded } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Dropdown,
  IconButton,
  ListDivider,
  Menu,
  MenuButton,
  MenuItem,
  Sheet,
  Table,
} from '@mui/joy'
import BaseTableList from 'components/tables/BaseTableList'
import TableRowContent from './TableRowContent'
import { tablePropTypesSchema } from './helpers'

const BaseTable = (props) => {
  const { headers, data = [], actions, buttonActions, noDataText } = props
  const localData = [...data]

  if (!data.length)
    return (
      <Alert variant="soft" color="neutral" size="md">
        {noDataText ? noDataText : 'Brak danych do wyświetlenia.'}
      </Alert>
    )

  return (
    <>
      <Sheet
        className="ProjectsTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          noWrap
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
            '& thead th': {
              '--TableCell-paddingY': '12px',
              '--TableCell-paddingX': '6px',
            },
            '& thead th:first-of-type': { paddingLeft: '20px' },
            '& thead th:last-of-type': { paddingRight: '20px' },
            '& tr > td:first-of-type': { paddingLeft: '20px' },
            '& tr > td:last-of-type': { paddingRight: '20px' },
            '& thead th.width-xs': { width: '80px' },
            '& thead th.width-sm': { width: '120px' },
            '& thead th.width-lg': { width: '160px' },
            '& thead th.width-xl': { width: '200px' },
            '& .text-center': { textAlign: 'center' },
            '& .actions': { textAlign: 'center' },
            '& .text-right': { textAlign: 'right' },
            minWidth: '800px',
          }}
        >
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.propName} className={header.className}>
                  {header.label}
                </th>
              ))}
              {(actions || buttonActions) && (
                <th style={{ width: actions ? 100 : 'auto', textAlign: 'center' }}>Akcje</th>
              )}
            </tr>
          </thead>
          <tbody>
            {[...localData].map((row, index) => (
              <tr key={row.id || index} className="hilightCell">
                {headers.map((header) => (
                  <td key={header.propName + row.id} className={header.rowClassName}>
                    <TableRowContent row={row} header={header} />
                  </td>
                ))}
                {(actions || buttonActions) && (
                  <td className="actions">
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        justifyContent: buttonActions ? 'center' : 'flex-start',
                      }}
                    >
                      {buttonActions &&
                        buttonActions.length &&
                        buttonActions.map((btnAction) => {
                          if (btnAction.display && !btnAction.display(row)) return ''
                          return (
                            <Button
                              key={btnAction.label}
                              size="xs"
                              variant={btnAction.variant ? btnAction.variant : 'outlined'}
                              color={btnAction.color || ''}
                              onClick={() => btnAction.func(row)}
                              loading={btnAction.loading || false}
                              startDecorator={btnAction.decor ? btnAction.decor : null}
                            >
                              {btnAction.label}
                            </Button>
                          )
                        })}
                      {actions && actions.length && (
                        <Dropdown>
                          <MenuButton
                            sx={{ m: 'auto' }}
                            slots={{ root: IconButton }}
                            slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
                          >
                            <MoreHorizRounded />
                          </MenuButton>
                          <Menu size="sm" sx={{ minWidth: 140 }}>
                            {actions.map((action) => (
                              <Sheet key={action.label}>
                                {action.divider && <ListDivider />}
                                <MenuItem
                                  color={action.color || 'primary'}
                                  onClick={() => action.func(row)}
                                  disabled={action.checkIfDisable ? action.checkIfDisable(row) : false}
                                >
                                  {action.label}
                                </MenuItem>
                              </Sheet>
                            ))}
                          </Menu>
                        </Dropdown>
                      )}
                    </Box>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      {/* MOBILE ONLY */}
      <BaseTableList {...props} />
    </>
  )
}

BaseTable.propTypes = tablePropTypesSchema

export default BaseTable
