import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Add, Warning } from '@mui/icons-material'
import { Alert, Box, Card, CardContent, Link, Stack, Typography } from '@mui/joy'
import { cleanupRequestStatus, getProjectDetails } from 'features/projectsSlice'
import {
  formatMoney,
  handleErrorMessage,
  isDateAfterOrToday,
  isDateBeforeOrToday,
  isTaskStatusOpen,
  standarizeStatus,
} from 'utils/helpers'
import { DATE_FORMAT, dateFilters, dateFiltersOptions, openFilters, openFiltersOptions, STATUS } from 'utils/config'
import { projectService } from 'services/projectsService'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import BaseTable from 'components/tables/BaseTable'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import FilterSelect from 'components/tables/FilterSelect'

const getHeaders = (project) => [
  { label: 'Data', propName: 'date', type: 'date', className: 'width-sm' },
  { label: 'Godziny', propName: 'hours' },
  {
    label: 'Ilość godzin',
    propName: 'hoursCount',
    ignoreOnMobile: true,
    rowClassName: 'text-center',
    className: 'text-center width-sm',
  },
  {
    label: 'Wynagrodzenie brutto',
    type: 'computed',
    propName: 'not_used',
    rowClassName: 'text-right',
    className: 'text-right width-xl',
    fn: (data) => {
      if (!project || !project.hourlyRate || !data.hoursCount) return '-'
      return formatMoney(project.hourlyRate * Number(data.hoursCount))
    },
  },
  { label: 'Lokalizacja', propName: 'localization', rowClassName: 'text-center', className: 'text-center' },
  { label: 'Uwagi', propName: 'notes', type: 'notes', rowClassName: 'text-center', className: 'width-xs text-center' },
  {
    label: 'Status',
    propName: 'assignment',
    type: 'assigmentModeUser',
    mobileOrder: -1,
    rowClassName: 'text-center',
    className: 'text-center',
  },
]

const UserProjectsDetailsView = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)
  const [dateFilter, setDateFilter] = useState(dateFilters.current.value)
  const [openFilter, setOpenFilter] = useState('')

  const { projectId } = useParams()
  const { currentProject, loading, error } = useSelector((s) => s.projects)
  const { userInfo, serverTime } = useSelector((s) => s.auth)

  const pagination = usePagination()

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const filteredTasks = useMemo(() => {
    if (!currentProject || !currentProject.tasks) return []

    let tempProjects = currentProject.tasks || []

    if (dateFilter === dateFilters.current.value)
      tempProjects = tempProjects.filter((t) => isDateAfterOrToday(t.date, serverTime))

    if (openFilter === openFilters.open.value)
      tempProjects = tempProjects.filter((t) => isTaskStatusOpen(t, serverTime))

    return tempProjects
  }, [currentProject, serverTime, dateFilter, openFilter])

  const handleApply = (task) => {
    setInnerLoading(true)
    projectService
      .userTaskApply(projectId, task.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zostałeś zapisany do zadania', { variant: 'success' })
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleApplyReserve = (task) => {
    setInnerLoading(true)
    projectService
      .userTaskApplyReserve(projectId, task.id)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zostałeś zapisany do zadania', { variant: 'success' })
        dispatch(getProjectDetails(projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (!projectId) return <Navigate to={routes.userProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Projekty', to: routes.userProjects }, { label: currentProject?.name || '?' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          columnGap: 3,
          rowGap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projekt:&nbsp;
          <Typography color="primary">{currentProject?.name}</Typography>
        </Typography>
        {currentProject?.coordinationPhone && (
          <Typography level="h4" component="h3" color="primary">
            Tel. koordynacyjny:{' '}
            <Link underline="hover" href={`tel:${currentProject.coordinationPhone}`}>
              {String(currentProject.coordinationPhone)
                .match(/.{1,3}/g)
                .join(' ')}
            </Link>
          </Typography>
        )}
      </Box>

      {currentProject && (
        <Card orientation="horizontal" variant="outlined">
          <CardContent>
            <Stack rowGap={0.5} columnGap={3} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Lokalizacja:
                </Typography>{' '}
                {currentProject.location}
              </Typography>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Data rozpoczęcia:
                </Typography>{' '}
                {dayjs(currentProject.startDate).format(DATE_FORMAT)}
              </Typography>
              <Typography>
                {' '}
                <Typography color="primary" fontWeight={500}>
                  Data zakończenia:
                </Typography>{' '}
                {dayjs(currentProject.endDate).format(DATE_FORMAT)}
              </Typography>
              <Typography>
                <Typography color="primary" fontWeight={500}>
                  Stawka godzinowa: brutto
                </Typography>{' '}
                {currentProject.hourlyRate} zł/h
              </Typography>
            </Stack>
            <Typography level="body-sm">{currentProject.description}</Typography>
          </CardContent>
        </Card>
      )}

      <FiltersWrapper
        sx={{ ml: { sm: 'auto' } }}
        renderFilters={
          <>
            <FilterSelect
              label="Filtrowanie po dacie"
              placeholder={dateFilters.all.label}
              options={dateFiltersOptions}
              value={dateFilter}
              setValue={setDateFilter}
            />
            <FilterSelect
              label="Filtrowanie po statusie"
              placeholder={openFilters.all.label}
              options={openFiltersOptions}
              value={openFilter}
              setValue={setOpenFilter}
            />
          </>
        }
      />

      <BaseTable
        headers={getHeaders(currentProject)}
        data={filteredTasks}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        buttonActions={[
          {
            label: 'Zapisz się',
            color: 'success',
            type: 'button',
            variant: 'solid',
            func: handleApply,
            loading: innerLoading,
            display: (task) => {
              if (isDateBeforeOrToday(task.date, serverTime)) return false
              if (standarizeStatus(task.status) === STATUS.done.value) return false
              return !task.assignment
            },
          },
          {
            label: 'Lista rezerwowa',
            decor: <Add fontSize="small" />,
            color: 'primary',
            type: 'button',
            func: handleApplyReserve,
            loading: innerLoading,
            display: (task) => {
              if (standarizeStatus(task.status) === STATUS.done.value) return false
              if (task.reserveList && task.reserveList.some((id) => id === userInfo.id)) return false
              if (task.assignment && task.assignment.userId === userInfo.id) return false
              return task.assignment
            },
          },
        ]}
        noDataText="Brak zadań do wyświetlenia."
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}
    </MainLayout>
  )
}

export default UserProjectsDetailsView
