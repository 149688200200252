import { CustomYup } from 'validations/CustomYup'
import {
  booleanFieldSchema,
  emailFieldSchema,
  firstNameFieldSchema,
  lastNameFieldSchema,
  passwordFieldSchema,
  phoneFieldSchema,
} from '../helpers'

const registerSchema = CustomYup.object().shape({
  firstName: firstNameFieldSchema.required(),
  lastName: lastNameFieldSchema.required(),
  phoneNumber: phoneFieldSchema.required(),
  email: emailFieldSchema.required(),
  password: passwordFieldSchema.required(),
  confirmPassword: CustomYup.string()
    .oneOf([CustomYup.ref('password')], 'Hasła muszą być identyczne.')
    .required(),
  acceptTerms: booleanFieldSchema.oneOf([true], 'Akceptacja zgody jest wymagana.').required(),
})

export default registerSchema
