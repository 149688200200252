import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Warning } from '@mui/icons-material'
import { Alert, Box, Typography } from '@mui/joy'
import { formatMoney, isDateAfterOrToday } from 'utils/helpers'
import { dateFilters, dateFiltersOptions } from 'utils/config'
import { cleanupRequestStatus, getMyTasks } from 'features/tasksSlice'
import Breadcrumbs from 'components/Breadcrumbs'
import BaseTable from 'components/tables/BaseTable'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import FilterSelect from 'components/tables/FilterSelect'
import routes from 'routes'
import { useNavigate } from 'react-router-dom'

const headers = [
  { label: 'Data', propName: 'date', type: 'date', className: 'width-sm' },
  { label: 'Godziny', propName: 'hours' },
  {
    label: 'Ilość godzin',
    propName: 'hoursCount',
    ignoreOnMobile: true,
    rowClassName: 'text-center',
    className: 'text-center width-sm',
  },
  {
    label: 'Wynagrodzenie brutto',
    propName: 'computed1',
    type: 'computed',
    rowClassName: 'text-right',
    className: 'text-right width-xl',
    fn: (task) => {
      if (!task.project || !task.project.hourlyRate || !task.hoursCount) return '-'
      return formatMoney(task.project.hourlyRate * Number(task.hoursCount))
    },
  },
  { label: 'Lokalizacja', propName: 'localization', rowClassName: 'text-center', className: 'text-center' },
  { label: 'Uwagi', propName: 'notes', type: 'notes', rowClassName: 'text-center', className: 'width-xs text-center' },
  {
    label: 'Status',
    propName: 'assignment',
    type: 'assigmentModeUser',
    mobileOrder: -1,
    rowClassName: 'text-center',
    className: 'text-center',
  },
]

const UserMyTasksView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [dateFilter, setDateFilter] = useState(dateFilters.current.value)

  const { tasks, loading, error } = useSelector((s) => s.tasks)
  const { userInfo, serverTime } = useSelector((s) => s.auth)

  const pagination = usePagination()

  useEffect(() => {
    if (!userInfo) return
    dispatch(getMyTasks(userInfo.id))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, userInfo])

  const filteredTasks = useMemo(() => {
    if (!tasks) return []
    if (dateFilter === dateFilters.current.value) return tasks.filter((t) => isDateAfterOrToday(t.date, serverTime))
    else return tasks
  }, [tasks, serverTime, dateFilter])

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Moje zadania' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Moje zadania
        </Typography>
      </Box>

      <FiltersWrapper
        sx={{ pt: 0 }}
        renderFilters={
          <FilterSelect
            label="Filtrowanie po dacie"
            placeholder={dateFilters.all.label}
            options={dateFiltersOptions}
            value={dateFilter}
            setValue={setDateFilter}
            sx={{ ml: { sm: 'auto' } }}
          />
        }
      />

      <BaseTable
        headers={headers}
        data={filteredTasks}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        noDataText="Brak zadań do wyświetlenia."
        buttonActions={[
          {
            label: 'Zobacz projekt',
            color: 'primary',
            type: 'button',
            func: (task) => navigate(routes.userProjectsDetails.replace(':projectId', task.project.id)),
          },
        ]}
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}
    </MainLayout>
  )
}

export default UserMyTasksView
