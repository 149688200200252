import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'features/authSlice'
import usersReducer from 'features/usersSlice'
import projectsReducer from 'features/projectsSlice'
import autocompleteReducer from 'features/autocompleteSlice'
import tasksReducer from 'features/tasksSlice'
import billingsReducer from 'features/billingsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    projects: projectsReducer,
    autocomplete: autocompleteReducer,
    tasks: tasksReducer,
    billings: billingsReducer,
  },
})
