import MainLayout from 'components/templates/MainLayout'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import routes from 'routes'
import { ROLES } from 'utils/config'

const HomeView = () => {
  const { userInfo } = useSelector((s) => s.auth)

  if (userInfo.role === ROLES.user.value) return <Navigate to={routes.user} />

  if (userInfo.role === ROLES.admin.value) return <Navigate to={routes.adminProjects} />

  return (
    <MainLayout>
      <p>HomeView</p>
    </MainLayout>
  )
}

export default HomeView
