import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/joy'
import { projectService } from 'services/projectsService'
import { handleErrorMessage } from 'utils/helpers'
import routes from 'routes'
import MainLayout from 'components/templates/MainLayout'
import Breadcrumbs from 'components/Breadcrumbs'
import TasksFormSubView from './common/TasksFormSubView'

const AdminTaskCreateView = () => {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [innerLoading, setInnerLoading] = useState(false)

  const handleAddTask = (values) => {
    setInnerLoading(true)

    projectService
      .addProjectTasks(projectId, values.tasks)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Zadania zostały dodane.', { variant: 'success' })
        navigate(routes.adminProjectsDetails.replace(':projectId', projectId))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (!projectId) return <Navigate to={routes.adminProjects} />

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            { label: state?.from || projectId, to: routes.adminProjectsDetails.replace(':projectId', projectId) },
            { label: 'Dodaj zadanie' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Nowe zadanie
        </Typography>
      </Box>

      <TasksFormSubView handleSubmit={handleAddTask} loading={innerLoading} />
    </MainLayout>
  )
}

export default AdminTaskCreateView
