import { useEffect } from 'react'
import { useFormik } from 'formik'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Divider, Link, Typography, Stack } from '@mui/joy'
import { cleanupRequestStatus, resetPassword } from 'features/authSlice'
import WarningIcon from '@mui/icons-material/Warning'
import routes from 'routes'
import resetPasswordSchema from 'validations/auth/resetPasswordSchema'
import CustomInputControl from 'components/forms/CustomInputControl'
import AuthLayout from 'components/templates/AuthLayout'

const ResetPasswordView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error, success, userInfo } = useSelector((s) => s.auth)

  useEffect(() => {
    return () => dispatch(cleanupRequestStatus())
  }, [])

  useEffect(() => {
    if (success) navigate(routes.login, { state: { message: 'Nowe hasło zostało wysłane.' } })
    if (userInfo) navigate(routes.home)
  }, [navigate, userInfo, success])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => dispatch(resetPassword(values)),
  })

  return (
    <AuthLayout>
      <Stack sx={{ gap: 4, mb: 2 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography component="h1" level="h3">
            Resetowanie hasła
          </Typography>
          <Typography level="body-sm">
            <Link component={RouterLink} to={routes.login} level="title-sm">
              Wróć do logowania
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={(theme) => ({
          [theme.getColorSchemeSelector('light')]: {
            color: { xs: '#FFF', md: 'text.tertiary' },
          },
        })}
      >
        lub
      </Divider>
      <Stack sx={{ gap: 4, mt: 2 }}>
        {error && (
          <Alert color="danger" variant="soft" startDecorator={<WarningIcon />}>
            {error}
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <CustomInputControl name="email" label="E-mail" formik={formik} fullWidth />
          <Stack sx={{ gap: 4, mt: 2 }}>
            <Button type="submit" fullWidth disabled={loading} loading={loading}>
              Resetuj hasło
            </Button>
          </Stack>
        </form>
      </Stack>
    </AuthLayout>
  )
}

export default ResetPasswordView
