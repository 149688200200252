import dayjs from 'dayjs'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { formatMoney, handleErrorMessage, standarizeStatus } from 'utils/helpers'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { PersonSearch, Warning } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy'
import { cleanupRequestStatus, getProjectDetails, getTaskDetails, getTaskReserveUsers } from 'features/projectsSlice'
import { DATE_FORMAT, STATUS } from 'utils/config'
import { projectService } from 'services/projectsService'
import routes from 'routes'
import Breadcrumbs from 'components/Breadcrumbs'
import BaseTable from 'components/tables/BaseTable'
import MainLayout from 'components/templates/MainLayout'
import usePagination from 'hooks/usePagination'
import PageLoader from 'components/PageLoader'
import CustomAutocompleteControl from 'components/forms/CustomAutocompleteControl'
import { getAutocompleteUsers } from 'features/autocompleteSlice'
import manualAssignUserSchema from 'validations/task/manualAssignUserSchema'
import DeleteModal from 'components/DeleteModal'

const headers = [
  { label: 'Imię', propName: 'firstName' },
  { label: 'Nazwisko', propName: 'lastName' },
  { label: 'E-mail', propName: 'email' },
]

const AdminTaskDetailsView = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [addCustomUser, setAddCustomUser] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const [unassignUserObject, setUnassignUserObject] = useState(false)
  const [removeFromListUserObject, setRemoveFromListUserObject] = useState(false)

  const { projectId, taskId } = useParams()
  const { usersList, loading: autocompleteLoading, error: autocompleteError } = useSelector((s) => s.autocomplete)
  const { currentTask, currentProject, currentTaskReserveUsers, loading, error } = useSelector((s) => s.projects)

  const pagination = usePagination()

  useEffect(() => {
    if (!currentProject) dispatch(getProjectDetails(projectId))
    dispatch(getTaskDetails({ projectId, taskId }))
    dispatch(getTaskReserveUsers({ projectId, taskId }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch])

  const handleAssignProjectTaskUser = (userId) => {
    setInnerLoading(true)
    projectService
      .adminAssignProjectTaskUser(projectId, taskId, userId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytkownik został przypisany.', { variant: 'success' })
        dispatch(getTaskDetails({ projectId, taskId }))
        dispatch(getTaskReserveUsers({ projectId, taskId }))
        setAddCustomUser(false)
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleUnassignProjectTaskUser = (userId) => {
    setInnerLoading(true)
    projectService
      .adminUnassignProjectTaskUser(projectId, taskId, userId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytkownik został odpięty.', { variant: 'success' })
        setUnassignUserObject(false)
        dispatch(getTaskDetails({ projectId, taskId }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  const handleRemoveUserFromReserve = (userId) => {
    setInnerLoading(true)
    projectService
      .adminRemoveProjectTaskReserveUser(projectId, taskId, userId)
      .then((res) => {
        console.log(res)
        enqueueSnackbar('Użytkownik został usunięty z lity.', { variant: 'success' })
        setRemoveFromListUserObject(false)
        dispatch(getTaskReserveUsers({ projectId, taskId }))
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(handleErrorMessage(err, 'Coś poszło nie tak.'), { variant: 'error' })
      })
      .finally(() => setInnerLoading(false))
  }

  if (!projectId || !taskId) return <Navigate to={routes.adminProjects} />

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <DeleteModal
        open={unassignUserObject}
        handleDelete={() => handleUnassignProjectTaskUser(unassignUserObject.userId)}
        loading={innerLoading}
        setObject={setUnassignUserObject}
        btnText="Odepnij"
      >
        Czy jesteś pewny, ze chcesz odpiąć:{' '}
        <Typography color="primary" fontWeight={500}>
          {unassignUserObject.firstName} {unassignUserObject.lastName}
        </Typography>
      </DeleteModal>
      <DeleteModal
        open={removeFromListUserObject}
        handleDelete={() => handleRemoveUserFromReserve(removeFromListUserObject.userId)}
        loading={innerLoading}
        setObject={setRemoveFromListUserObject}
      >
        Czy jesteś pewny, ze chcesz usunąć z listy rezerwowej:{' '}
        <Typography color="primary" fontWeight={500}>
          {removeFromListUserObject.firstName} {removeFromListUserObject.lastName}
        </Typography>
      </DeleteModal>
      <Modal open={Boolean(addCustomUser)} onClose={() => setAddCustomUser(false)}>
        <ModalDialog variant="outlined" role="alertdialog" sx={{ width: '100%', maxWidth: '400px' }}>
          <DialogTitle>Ręczne przypisanie użytkownika</DialogTitle>
          <Divider />
          <Formik
            initialValues={{ user: null }}
            validationSchema={manualAssignUserSchema}
            onSubmit={(values, actions) => {
              const { user } = values
              if (user.id) handleAssignProjectTaskUser(user.id)
              actions.resetForm()
            }}
          >
            {({ handleSubmit, resetForm, ...props }) => (
              <form onSubmit={handleSubmit}>
                {autocompleteError && (
                  <Alert color="danger" variant="soft" startDecorator={<Warning />}>
                    {error}
                  </Alert>
                )}
                {autocompleteLoading && <LinearProgress sx={{ my: 2 }} />}
                {usersList && (
                  <DialogContent sx={{ overflow: 'visible' }}>
                    {console.log(usersList)}
                    <CustomAutocompleteControl
                      name="user"
                      label="Szukaj użytkownika"
                      options={usersList}
                      formik={props}
                    />
                    <DialogActions>
                      <Button type="submit" variant="solid" color="danger" loading={innerLoading}>
                        Przypisz
                      </Button>
                      <Button
                        variant="plain"
                        color="neutral"
                        onClick={() => {
                          setAddCustomUser(false)
                          resetForm()
                        }}
                        loading={innerLoading || autocompleteLoading}
                      >
                        Anuluj
                      </Button>
                    </DialogActions>
                  </DialogContent>
                )}
              </form>
            )}
          </Formik>
        </ModalDialog>
      </Modal>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          items={[
            { label: 'Projekty', to: routes.adminProjects },
            { label: state?.from || projectId, to: routes.adminProjectsDetails.replace(':projectId', projectId) },
            { label: currentTask?.localization || '?' },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Zadanie:&nbsp;
          <Typography color="primary">{currentTask?.localization}</Typography>
        </Typography>
        <Button
          color="primary"
          startDecorator={<PersonSearch fontSize="small" />}
          onClick={() => {
            setAddCustomUser(true)
            dispatch(getAutocompleteUsers())
          }}
        >
          Przypisz użytkownika ręcznie
        </Button>
      </Box>

      {currentTask && (
        <>
          <Card orientation="horizontal" variant="outlined">
            <CardContent>
              <Stack rowGap={0.5} columnGap={3} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
                <Typography>
                  <Typography color="primary" fontWeight={500}>
                    Nr:
                  </Typography>{' '}
                  {currentTask.lp}
                </Typography>
                <Typography>
                  <Typography color="primary" fontWeight={500}>
                    Data:
                  </Typography>{' '}
                  {dayjs(currentTask.date).format(DATE_FORMAT)}
                </Typography>
                <Typography>
                  <Typography color="primary" fontWeight={500}>
                    Przystanek/Stacja:
                  </Typography>{' '}
                  {currentTask.localization}
                </Typography>
                <Typography>
                  <Typography color="primary" fontWeight={500}>
                    Godziny:
                  </Typography>{' '}
                  {currentTask.hours}
                </Typography>
                <Typography>
                  <Typography color="primary" fontWeight={500}>
                    Ilość godzin:
                  </Typography>{' '}
                  {currentTask.hoursCount}
                </Typography>
                {currentProject && currentProject.hourlyRate && (
                  <Typography>
                    <Typography color="primary" fontWeight={500}>
                      Stawka projektowa brutto:
                    </Typography>{' '}
                    {formatMoney(Number(currentProject.hourlyRate))}
                  </Typography>
                )}
                {currentProject && currentProject.hourlyRate && currentTask.hoursCount && (
                  <Typography>
                    <Typography color="primary" fontWeight={500}>
                      Wynagrodzenie brutto:
                    </Typography>{' '}
                    {formatMoney(Number(currentProject.hourlyRate) * Number(currentTask.hoursCount))}
                  </Typography>
                )}
                {currentProject && currentProject.lp && (
                  <Typography>
                    <Typography color="primary" fontWeight={500}>
                      Numer porządkowy:
                    </Typography>{' '}
                    {currentTask.lp}
                  </Typography>
                )}
              </Stack>
              <Typography level="body-sm">{currentTask.notes}</Typography>
            </CardContent>
          </Card>
          <>
            <Typography color="primary" fontWeight={500} sx={{ mt: 2 }}>
              Przypisany:
            </Typography>
            <BaseTable
              headers={headers}
              data={currentTask.assignment ? [{ ...currentTask.assignment }] : []}
              offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
              noDataText="Brak przypisanej osoby."
              buttonActions={[
                {
                  label: 'Odepnij',
                  color: 'danger',
                  func: (el) => setUnassignUserObject(el),
                  loading: innerLoading,
                  display: () => standarizeStatus(currentTask.status) !== STATUS.done.value,
                },
              ]}
            />
          </>
          {currentTaskReserveUsers && (
            <>
              <Typography color="primary" fontWeight={500} sx={{ mt: 2 }}>
                Lista rezerwowa:
              </Typography>
              <BaseTable
                headers={headers}
                data={currentTaskReserveUsers}
                offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
                noDataText="Brak listy rezerwowej."
                buttonActions={[
                  {
                    label: 'Przypisz do zadania',
                    color: 'primary',
                    func: (el) => handleAssignProjectTaskUser(el.userId),
                    loading: innerLoading,
                    display: () => standarizeStatus(currentTask.status) !== STATUS.done.value,
                  },
                  {
                    label: 'Usuń z listy',
                    color: 'danger',
                    func: (user) => setRemoveFromListUserObject(user),
                    loading: innerLoading,
                  },
                ]}
              />
            </>
          )}
        </>
      )}

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}

      {/* <TablePaginationDesktop pagination={pagination} /> */}
    </MainLayout>
  )
}

export default AdminTaskDetailsView
