import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { autocompleteService } from 'services/autocompleteService'
import { handleErrorMessage } from 'utils/helpers'

const initialState = {
  usersList: [],
  loading: false,
  error: null,
}

export const getAutocompleteUsers = createAsyncThunk('autocomplete/login', async (_, { rejectWithValue }) => {
  try {
    const response = await autocompleteService.getUsers()
    return response.data
  } catch (error) {
    return rejectWithValue(handleErrorMessage(error))
  }
})

export const autocompleteSlice = createSlice({
  name: 'autocomplete',
  initialState,
  reducers: {
    clean: () => {
      return initialState
    },
    cleanupRequestStatus: (state) => {
      state.error = false
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      // Users
      .addCase(getAutocompleteUsers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAutocompleteUsers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.usersList = payload.map((user) => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
        }))
      })
      .addCase(getAutocompleteUsers.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export const { clean, cleanupRequestStatus } = autocompleteSlice.actions

export default autocompleteSlice.reducer
